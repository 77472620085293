import React from 'react';
import { GlobalProvider, useGlobalContext } from './Context/GlobalContext';
import Layout from './Layout/Layout';
import Loader from './Components/Loader/Loader';
import Toast from './Components/Toast/Toast';



const App = () => {
  const { loading, toast } = useGlobalContext();
  return (
      <div className="font-dm-sans">
        {loading && <Loader />}
        {toast && <Toast message={toast.message} type={toast.type} />}
        <Layout/>
      </div>
  )
};

const WrappedApp = () => (
  <GlobalProvider>
    <App />
  </GlobalProvider>
)

export default WrappedApp;

//export default App;
