import React, { useEffect, useState } from 'react';
import { Modal, Button, Box, Typography, IconButton } from '@mui/material';
import './PromotionDetailsModalStyles.css';  // Import the CSS file
import { useGlobalContext } from '../../Context/GlobalContext';
import { getPromotionDetailsApi } from '../../API/createPromotion/createPromotion';
import PromotionEditModal from '../../Components/PromotionEditModalComponent/PromotionEditModalComponent';

const PromotionDetailsModal = ({ isVisible, onClose, promotionId, getPromotionLists }) => {
  const { setLoading, showToast } = useGlobalContext();
  const [detailsPromotion, setDetailsPromotion] = useState([]);
  const [promotionCalenderEditModalVisible, setpromotionCalenderEditModalVisible] = useState(false);
  
  useEffect(() => {
    if(isVisible === true){
      getDetailsofPromotionApi(promotionId);
    }
  }, [isVisible]);

  const getDetailsofPromotionApi = async (promotionId) => {
    setLoading(true);
    let response = await getPromotionDetailsApi(promotionId);
    console.log("responseresponse",response)
    const { promotion, success } = response.data;
    if (response.status === 200 && success === true) {
      setDetailsPromotion(promotion);
      setLoading(false);
    }
  }

  const handlePromotionEdit = () => {
    setpromotionCalenderEditModalVisible(true);
    onClose();
  };

  const handlePromotionEditModalCancel = () => {
    setpromotionCalenderEditModalVisible(false);
  };
  return (
    <div>
      <Modal
        open={isVisible}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="promotionDetailModalContainer"
      >
        <Box className="promotionDetailModalContent">
          <Box className="promotionDetailModalHeader">
            <Typography className="promotionDetailModalTitle">Promotion Details</Typography>
            <Box className="promotionDetailModalHeaderActions">
              <img src={process.env.REACT_APP_URL+'edit.svg'} alt='edit-icon' onClick={handlePromotionEdit} className='w-[16px] h-[16px]'/>
              <img src={process.env.REACT_APP_URL+'Cross-1171276068.svg'} alt='cross-icon' onClick={onClose} />
            </Box>
          </Box>
          {detailsPromotion.length > 0 ? (
            detailsPromotion.map((ele, index) => {
              return (
                <Box key={ele.promotionId} className="promotionDetailModalItem">
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Name</p>
                    <p className='promotionDetailsData'>{ele.promotionName}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Description</p>
                    <p className='promotionDetailsData'>{ele.promotionDetail}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Start Date</p>
                    <p className='promotionDetailsData'>{new Date(ele.startDate).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/(\d{1,2})(?=\s)/, d => `${d}${['th','st','nd','rd'][(d%10>3||Math.floor(d/10)==1)?0:d%10]}`)}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion End Date</p>
                    <p className='promotionDetailsData'>{new Date(ele.endDate).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/(\d{1,2})(?=\s)/, d => `${d}${['th','st','nd','rd'][(d%10>3||Math.floor(d/10)==1)?0:d%10]}`)}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Discount</p>
                    <p className='promotionDetailsData'>{ele.promotionDiscount}%</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Category</p>
                    <p className='promotionDetailsData'>{ele.promotionCategory.join(', ')}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Type</p>
                    <p className='promotionDetailsData'>{ele.promotionType}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Delivery</p>
                    <p className='promotionDetailsData'>{ele.deliveryPlatform.join(', ')}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Key Audience </p>
                    <p className='promotionDetailsData'>{ele.keyAudience.join(', ')}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Key Target </p>
                    <p className='promotionDetailsData'>{ele.keyTarget.join(', ')}</p>
                  </div>
                  {/* <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Location</p>
                    <p className='promotionDetailsData'>{ele.location.join(', ')}</p>
                  </div> */}
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Overall Content Tone</p>
                    <p className='promotionDetailsData'>{ele.overallContentTone.join(', ')}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Language</p>
                    <p className='promotionDetailsData'>{ele.promotionLanguage}</p>
                  </div>
                  <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Created At</p>
                    <p className='promotionDetailsData'>{new Date(ele.createdAt).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/(\d{1,2})(?=\s)/, d => `${d}${['th','st','nd','rd'][(d%10>3||Math.floor(d/10)==1)?0:d%10]}`)}</p>
                  </div>
                  {/* <div className="promotionDetailModalSection">
                    <p className='promotionTitles'>Promotion Updated At</p>
                    <p className='promotionDetailsData'>{new Date(ele.updatedAt).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/(\d{1,2})(?=\s)/, d => `${d}${['th','st','nd','rd'][(d%10>3||Math.floor(d/10)==1)?0:d%10]}`)}</p>
                  </div> */}
                </Box>
              )
            }
            )) : (
            <p className='promotionDetailsData'>No promotion data available</p>
          )}
        </Box>
      </Modal>
      
        <PromotionEditModal
            isVisible={promotionCalenderEditModalVisible}
            evenId={promotionId}
            onClose={handlePromotionEditModalCancel} 
            getPromotionLists={getPromotionLists}
        />
    </div>
  );
};

export default PromotionDetailsModal;
