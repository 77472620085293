import axios from "axios";
// import config  from "./Config";
import { useNavigate } from 'react-router-dom';


const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const APIPAYMENT = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_API_URL,
});

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Token expired or unauthorized, redirect to login
      localStorage.removeItem('token'); // Clear the token from localStorage
       window.location.href = '/login'; 
    }
    return Promise.reject(error);
  }
);

API.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
  return req;
});
export default API;

// CREATE =>  POST: add a new customer to the server
export function postApi(curl, params={}) {
    return API.post(curl, params);
}
  
  // READ
  export function getAll(curl) {
    return API.get(`${curl}`);
  }

  export function getPaymentApi(curl) {
    return APIPAYMENT.get(`${curl}`);
  }

  export function postPaymentApi(curl, params={}) {
    return APIPAYMENT.post(curl, params);
  }
  
  export function getDetailsById(url, id) {
    return API.get(`${url}?id=${id}`);
  }
  


  


//   // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
//   // items => filtered/sorted result
//   export function findCustomers(queryParams) {
//     return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
//   }
  
//   // UPDATE => PUT: update the customer on the server
//   export function updateCustomer(customer) {
//     return axios.put(`${CUSTOMERS_URL}/${customer.id}`, { customer });
//   }
  
//   // UPDATE Status
//   export function updateStatusForCustomers(ids, status) {
//     return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
//       ids,
//       status
//     });
//   }
  
//   // DELETE => delete the customer from the server
//   export function deleteCustomer(customerId) {
//     return axios.delete(`${CUSTOMERS_URL}/${customerId}`);
//   }
  
//   // DELETE Customers by ids
//   export function deleteCustomers(ids) {
//     return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
//   }
  

/* Setup your API client */