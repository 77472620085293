import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Grid, Button, Modal } from '@mui/material';
import  './UpgradeSubscriptionPageStyles.css';
import { useGlobalContext } from '../../Context/GlobalContext';
import { getPreviousPlanById, updateSubscription } from '../../API/Subscriptions/Subscriptions';
import { getPaymentApi } from '../../apiClient';
const UpgradeSubscriptionPage = () => {
  const user_id=localStorage.getItem('userId');
  const [value, setValue] = useState(0);
  const [planList, setPlanList] = useState([]);
  const [currentPlan,setCurrentPlan] =useState();
  const [choosenPlanId, setChoosenPlanId] = useState('');
  const [upgradeCheckoutLinkOpen, setUpgradeCheckoutLinkOpen] = useState(false);
  const [upgradeSubscriptionPayModalOpen, setupgradeSubscriptionPayModalOpen] = useState(false);
  const { setLoading, showToast } = useGlobalContext();

  useEffect(() => {
    if (user_id) {
      getPreviousPlan();
    }
  }, [user_id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getPreviousPlan = async () => {
    setLoading(true);
    try {
      const payload = {
        userId: user_id,
      };
      const response = await getPreviousPlanById(payload);
      if (response) {
        setCurrentPlan(response?.data?.planId);
      }
    } catch (error) {
      showToast(error?.response?.data?.err?.message || "Error! Please try again", 'error');
      console.error("Error fetching previous plan:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const upgradeSubscriptionCheckout = async () => {
    setLoading(true);
    try {
      const payload = {
        userId: user_id,
        planId: choosenPlanId,
      };
      let response = await updateSubscription(payload)
        if (response) {
          showToast('Subscription plan updated successfully', "success");
          setLoading(false);
          handleUpgradeSubscriptionPayModalClose();
          getPreviousPlan();
          handlePlanChooseNext();
        }
    } catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }
    
  }

  // const handleUpgradeSubscriptionPayModalOpen = () => {
  //   setupgradeSubscriptionPayModalOpen(true);
  // };

  const handleUpgradeSubscriptionPayModalClose = () => {
    setupgradeSubscriptionPayModalOpen(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  useEffect(() => {
    handlePlanChooseNext()
  }, []); 

  const handlePlanChooseNext = async () => {
    let response = await getPaymentApi('api/plan')
     const { plans } = response.data;
    if (plans.length>0) {
      setPlanList(plans)
    }
  }

  const filteredPlans = planList?.filter(item => item.interval === 'month')
  .map((plan, index) => {
    return {
      ...plan,
      newValue: plan.value * 2, 
      index: index
    };
  });

  const filteredYearlyPlans = planList?.filter(item => item.interval === 'year')
  .map((plan, index) => {
    return {
      ...plan,
      newValue: plan.value * 2, 
      index: index
    };
  });

  const handleCheckout=(planId)=>{
    setChoosenPlanId(planId);
    setUpgradeCheckoutLinkOpen(true);
    setupgradeSubscriptionPayModalOpen(true);
  }

  return (
    <>
      <div className="subscriptionContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <p className="ml-[30px] text-[#333] text-[24px] font-medium leading-[30px] tracking-[-0.48px]">
              Current Step: Subscription Plan
            </p>
            <p className="ml-[30px] text-[#646464] text-[16px] font-medium leading-[28px]">
              Current Step: Subscription Plan
            </p>
          </Grid>
          <Grid item xs={12} md={6} className="flex justify-center">
            <div className="upgradePlanTab">
              <Tabs
                value={value}
                onChange={handleChange}
                className="upgradeCustomTabs"
              >
                <Tab label="Monthly" className="upgradeCustomTab" />
                <Tab label="Annually" className="upgradeCustomTab" />
              </Tabs>
              <p className="text-[#1D2127] text-[13px] font-[500]">
                Need a Custom Plan!{" "}
                <span className="font-[700] text-[13px] text-[#684FFF65]">
                  Contact Us
                </span>
              </p>
            </div>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
          <div className="upgradePlanTabContainer">
            <Grid container className="root">
              {filteredPlans.map((plan, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={
                    plan?.id === currentPlan
                      ? "upgradePlanTabColumnSelected"
                      : "upgradePlanTabColumn"
                  }
                >
                  <div className="upgradePlanTabDetails">
                    <p className="font-inter font-[600] leading-normal tracking-wide text-[16px] mt-8">
                      {plan.product.name}
                    </p>
                    <p>
                      <span className="text-[#1D2127] font-inter text-[36px] font-[800] leading-normal tracking-wider">
                        ${plan.amount / 100}
                      </span>
                      <span className="text-[#646464] font-inter text-[12px] font-[400] leading-normal tracking-wider">
                        per {plan.interval}
                      </span>
                    </p>
                    <p className="text-[#646464] font-inter text-[14px] font-[500] leading-normal tracking-wider">
                      Marketing up to 1200
                      <br /> customers
                    </p>
                    <div className="mt-4">
                      {plan?.product?.marketing_features?.map(
                        (marketing, idx) => (
                          <div className="upgradePlanItemDes" key={idx}>
                            <img
                              src={process.env.REACT_APP_URL + "Tick.svg"}
                              className="w-6 h-5"
                            />
                            <p className="text-[#171717] font-inter text-[14px] font-[300] leading-normal tracking-wider pt-0">
                              {marketing?.name}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                    <div className="upgradePlanButtonDiv">
                      <Button
                        fullWidth
                        variant="subscription"
                        className="upgradePlanButton"
                        onClick={() => {
                          handleCheckout(plan?.id);
                        }}
                      >
                        Choose Plan
                      </Button>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="upgradePlanTabContainer">
            <Grid container className="root">
              {filteredYearlyPlans.map((yearlyPlan, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={
                    yearlyPlan?.id === currentPlan
                      ? "upgradePlanTabColumnSelected"
                      : "upgradePlanTabColumn"
                  }
                >
                  <div className="upgradePlanTabDetails">
                    <p className="font-inter font-[600] leading-normal tracking-wide text-[16px] mt-8">
                      {yearlyPlan.product.name}
                    </p>
                    <p>
                      <span className="text-[#1D2127] font-inter text-[36px] font-[800] leading-normal tracking-wider">
                        ${yearlyPlan.amount / 100}
                      </span>
                      <span className="text-[#646464] font-inter text-[12px] font-[400] leading-normal tracking-wider">
                        per {yearlyPlan.interval}
                      </span>
                    </p>
                    <p className="text-[#646464] font-inter text-[14px] font-[500] leading-normal tracking-wider">
                      Marketing up to 1200
                      <br /> customers
                    </p>
                    <div className="mt-4">
                      {yearlyPlan?.product?.marketing_features?.map(
                        (marketingDetails, idx) => (
                          <div className="upgradePlanItemDes" key={idx}>
                            <img
                              src={process.env.REACT_APP_URL + "Tick.svg"}
                              className="w-6 h-5"
                            />
                            <p className="text-[#171717] font-inter text-[14px] font-[300] leading-normal tracking-wider pt-0">
                              {marketingDetails?.name}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                    <div className="upgradePlanButtonDiv">
                      <Button
                        fullWidth
                        variant="subscription"
                        className="upgradePlanButton"
                        onClick={() => {
                          handleCheckout(yearlyPlan?.id);
                        }}
                        // onClick={() => upgradeSubscriptionCheckout(yearlyPlan?.id)}
                      >
                        Choose Plan
                      </Button>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </TabPanel>
      </div>
      {upgradeCheckoutLinkOpen === true && (
        <Modal
          open={upgradeSubscriptionPayModalOpen}
          onClose={handleUpgradeSubscriptionPayModalClose}
          className="modal"
        >
          <div className="upgradeModalPaper">
            <div className="subsHeadingCrossPositionUpgrade">
              <p className="upgradeSubsModalHeading">
                {" "}
                Upgrade your subscription{" "}
              </p>
              <div
                className="upgradeSubscriptionCrossOnModal"
                onClick={handleUpgradeSubscriptionPayModalClose}
              >
                <img
                  src={process.env.REACT_APP_URL + "Cross-1171276068.svg"}
                  alt="cross"
                />
              </div>
            </div>
            <div className="bodyContainer">
              <p>Do you want to proceed with the upgrade plan</p>
            </div>
            <div className="flex justify-end ">
              <Button
                variant="primary"
                width="100px"
                onClick={upgradeSubscriptionCheckout}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default UpgradeSubscriptionPage
