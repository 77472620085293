import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const StepperComponent = ({ steps, currentStep, storeType })=> {
    return (
        <div>
            <p className='text-[#333] text-[24px] leading-[30px] tracking-[-0.48px] font-medium pl-8 mt-10'>Welcome to PushSend Ai</p>
            <div className='mt-12 pl-12 mb-12'>
              <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400 pl-8">  
                {steps.map((step, index) => (                
                <li key={index} className="mb-7 ms-6 pr-16">            
                    <span className={`absolute flex items-center justify-center w-6 h-7 px-6 py-2 -start-6 rounded-full ${currentStep === index ?'bg-[#9662FF] text-white': currentStep > index?(storeType==="Online Stores" && index===2)?'bg-[#FFF3F3] border-[1.5px] border-[#FF5353] text-[#FF5353]':'bg-[#C8ADFF] text-white':(storeType==="Online Stores" && index===2)?'bg-[#FFF3F3] border-[1.5px] border-[#FF5353] text-[#FF5353]':'bg-[#FFF] border border-[#9261FF] text-[#7E58FF]'} `}>
                      {currentStep === index ?'0'+(index+1):currentStep > index?(storeType==="Online Stores" && index==2)?<CloseIcon></CloseIcon>:<CheckOutlinedIcon></CheckOutlinedIcon>:(storeType==="Online Stores" && index==2)?<CloseIcon></CloseIcon>:'0'+(index+1)}
                    </span>
                    <h3 className="text-[#171717] text-[14px] font-normal leading-[30px] tracking-[-0.28px]">{step.name}</h3>
                </li>
                ))}
                {/* <li className="mb-16 ms-6">
                    <span className="absolute flex items-center justify-center w-6 h-7 px-6 py-2 -start-4 rounded-full bg-[#9662FF] text-white">
                      02
                    </span>
                    <h3 className="font-medium leading-tight">Account Info</h3>
                </li> */}
              </ol>
                <p className='text-[#171717] text-base font-medium leading-[28px] tracking-[-0.32px]'>Related Helpful Links</p>
                <div className='text-[#4B30F3] text-[13px] font-normal underline mt-4'>
                    <Link >How do I find my store key?</Link><br/>
                    <Link >Do you store all my data?</Link><br/>
                    <Link >What happens if I activate my store?</Link><br/>
                    <Link >Why do you need to activate my store?</Link><br/>
                </div>
              
            </div>
          </div>
    )
  }
  
   export default StepperComponent;