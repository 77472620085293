import React from 'react'
import SignupComponent from '../../Components/SignupComponent/SignupComponent'

const SignupContainer = () => {
  return (
    <><SignupComponent/></>
  )
}

export default SignupContainer
