let googleMapsScriptLoaded = false;
let googleMapsScriptLoading = false;
let googleMapsScriptLoadingCallbacks = [];

export const loadGoogleMapsApi = (apiKey, callback) => {
  if (googleMapsScriptLoaded) {
    callback();
    return;
  }

  googleMapsScriptLoadingCallbacks.push(callback);

  if (googleMapsScriptLoading) {
    return;
  }

  googleMapsScriptLoading = true;

  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
  script.async = true;
  script.defer = true;
  script.onload = () => {
    googleMapsScriptLoaded = true;
    googleMapsScriptLoadingCallbacks.forEach(cb => cb());
    googleMapsScriptLoadingCallbacks = [];
  };
  script.onerror = (error) => {
    console.error('Error loading Google Maps API:', error);
  };
  document.head.appendChild(script);
};
