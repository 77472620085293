import React, { useEffect, useState } from 'react';
import './PromotionListPageStyles.css';
import { Link } from 'react-router-dom';
import {
  Grid,
  Button
} from "@mui/material";
import { getPromotionListApi } from '../../API/createPromotion/createPromotion';
import { useGlobalContext } from '../../Context/GlobalContext';
import PromotionCalendar from '../../Components/PromotionCalendarComponent/PromotionCalendarComponent';
import PromotionDetailsModal from '../../Components/PromotionDetailsModalComponent/PromotionDetailsModalComponent';
import ViewConversationComponent from '../../Components/ViewConversationComponent/ViewConversationComponent';



const PromotionListPage = () => {
  const user_id = localStorage.getItem('userId');
  const [promotionList, setPromotionList] = useState([]);
  const [isPromotionDetailModalVisible, setIsPromotionDetailModalVisible] = useState(false);
  const [isPromotionClicked, setIsPromotionClicked] = useState(false);
  const [promotionId, setPromotionId] = useState();
  const [viewConversationPromotion, setViewConversationPromotion] = useState([]);
  const [isViewConversationVisible, setIsViewConversationVisible] = useState(false);
  const [isViewPromotionList, setIsViewPromotionList] = useState(true);
  const { setLoading, showToast } = useGlobalContext();
  const [activeDiv, setActiveDiv] = useState('gridView');


  const handleIconClick = (divName) => {
    setActiveDiv(divName);
  };

  useEffect(() => {
    getPromotionLists();
  }, []);

  const getPromotionLists = async () => {
    setLoading(true);
    let response = await getPromotionListApi(user_id);
    const { promotions, success } = response.data;
    if (response.status === 200 && promotions.length > 0 && success === true) {
      setPromotionList(promotions);
    }
    setLoading(false);
  }

  const handlePromotionDetailsModalCancel = () => {
    setIsPromotionDetailModalVisible(false);
    setIsPromotionClicked(false);
  };

  // const handleViewConversationCancel = () => {
  //   setIsViewConversationVisible(false);
  // }

  useEffect(() => {
    if (isPromotionClicked) {
      setIsPromotionDetailModalVisible(true);
    }
  }, [isPromotionClicked])

  const handlePromotionEventClick = (promotion) => {
    //const eventpromotionId = promotion.promotionId;
    setPromotionId(promotion);
    setIsPromotionClicked(true);
  }

  const getViewConversionofGeneratedContent = (promotion) => {
    setViewConversationPromotion(promotion);
    setIsViewConversationVisible(true);
    setIsViewPromotionList(false);
  }

  return (
    <>
      {isViewPromotionList === true &&
        <div className='promotionListWrapper'>
          { promotionList.length > 0 ? 
            <div className='promotionListView'>
              <img src={`${process.env.REACT_APP_URL}${activeDiv === 'gridView' ? 'table-Active.svg' : 'table-inactive.svg'}`} alt='promotionListView' className='mr-2 mt-4 cursor-pointer' onClick={() => handleIconClick('gridView')} />
              <img src={`${process.env.REACT_APP_URL}${activeDiv === 'calendarView' ? 'Calender-active.svg' : 'Calender-inactive.svg'}`} alt='promotionCalendarView' className='mt-4 cursor-pointer' onClick={() => handleIconClick('calendarView')} />
            </div>
            :null 
          }
          <div className='p-7'>
            {activeDiv === 'gridView' && promotionList.length > 0 ?
              <Grid container spacing={2}>
                {promotionList.map((promotion, index) =>
                  <Grid item xs={12} md={4}>
                    <div className='promotionCard'>
                      <div className='listCardTopBadge'>
                        <div className='rightTopBadge'>
                          <p className='badgeContent'>Content generation is on inprogress</p>
                        </div>
                      </div>
                      <div className='promotionCardDetails'>
                        <p className='promotionCardTitle'>{promotion.promotionName}</p>
                        <p className='promotionDes'>{promotion.promotionDetail}</p>
                        <div className='promotionDetailsInChip'>
                          <div className='detailsInChip'>
                            <p className='chipContent'>{promotion.promotionDiscount}% Discount</p>
                          </div>
                          {/* <div className='detailsInChip'>
                            <p className='chipContent'>{promotion?.promotionCategory.join(', ')}</p>
                          </div> */}
                          <div className='detailsInChip'>
                            <p className='chipContent'>{promotion.promotionType} promotion</p>
                          </div>
                        </div>
                        <div className='promotionDateContainer'>
                          <div>
                            <p className='promotionDateTitle'>Start Date</p>
                            <p className='text-[#5F73AE] text-[10px] font-medium leading-normal ml-2'>
                              {new Date(promotion.startDate)
                                .toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC' })
                                .replace(/(\d+)(?=\D)/, (d) => d + ["th", "st", "nd", "rd"][(d % 10 > 3 || [11, 12, 13].includes(d % 100)) ? 0 : d % 10])}
                            </p>
                          </div>
                          <div>
                            <p className='promotionDateTitle'>End Date</p>
                            <p className='text-[#5F73AE] text-[10px] font-medium leading-normal ml-2'>
                              {new Date(promotion.endDate)
                                .toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC' })
                                .replace(/(\d+)(?=\D)/, (d) => d + ["th", "st", "nd", "rd"][(d % 10 > 3 || [11, 12, 13].includes(d % 100)) ? 0 : d % 10])}
                            </p>
                          </div>
                        </div>
                        <div className='promotionButton'>
                          <Button
                            //type="submit"
                            variant="promotion"
                            className='w-[45%]'
                            onClick={() => handlePromotionEventClick((promotion.promotionId))}
                          >
                            View Details
                          </Button>
                          <Button
                            variant="promotion"
                            className='w-[55%]'
                            // href='/app/view-conversation'
                            onClick={() => getViewConversionofGeneratedContent(promotion)}
                          >
                            View Conversation
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
                {/* <Grid item xs={12} md={4}>
                  <div className='promotionCard'>
                    <div className='listCardTopBadge'>
                      <div className='rightTopBadgeForGeneratedContent'>
                        <p className='badgeContent'>Content generation is on inprogress</p>
                      </div>
                    </div>
                    <div className='promotionCardDetails'>
                      <p className='promotionCardTitle'>Promotion1</p>
                      <p className='promotionDes'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate</p>
                      <div className='promotionDetailsInChip'>
                        <div className='detailsInChip'>
                          <p className='chipContent'>12% Discount</p>
                        </div>
                        <div className='detailsInChip'>
                          <p className='chipContent'>Fashion & Beauty</p>
                        </div>
                        <div className='detailsInChip'>
                          <p className='chipContent'>In-store promotion</p>
                        </div>
                      </div>
                      <div className='promotionDateContainer'>
                        <div>
                          <p className='promotionDateTitle'>Start Date</p>
                          <p className='text-[#5F73AE] text-[10px] font-medium leading-normal ml-2'>9th August</p>
                        </div>
                        <div>
                          <p className='promotionDateTitle'>End Date</p>
                          <p className='text-[#5F73AE] text-[10px] font-medium leading-normal ml-2'>11th August</p>
                        </div>
                      </div>
                      <div className='promotionButton'>
                        <Button
                          variant="promotion"
                          className='w-[45%]'
                        >
                          View Details
                        </Button>
                        <Button
                          variant="promotion"
                          className='w-[55%]'
                          href='/app/view-conversation'
                          onClick={() => getViewConversionofGeneratedContent((promotion.promotionId))}
                        >
                          View Conversation
                        </Button>
                        
                      </div>

                    </div>
                  </div>
                </Grid> */}
              </Grid>
              : activeDiv === 'calendarView' && promotionList.length > 0 ?
                <div>
                  <PromotionCalendar
                    handlePromotionEventClick={handlePromotionEventClick}
                  />
                </div>
              : <div className='noPromotionImageWrapper'>
                  <div className='noPromotionImagecontainer'>
                    <p className="text-gray-600 text-[20px] not-italic font-normal leading-normal">No Promotions Found</p>
                    <img src={process.env.REACT_APP_URL+'login-banner-old.png'} alt="Rectangle" className='dashboardImage'/>
                    <div className='flex justify-center align-center'>
                      <Button
                        variant="primary"
                        href='/app/create-promotion'
                      >
                        Create Promotion
                      </Button>
                    </div>
                  </div> 
                </div>
            }
          </div>
        </div>
      }
      <div>
        <PromotionDetailsModal
          promotionId={promotionId}
          isVisible={isPromotionDetailModalVisible}
          onClose={handlePromotionDetailsModalCancel}
          getPromotionLists={getPromotionLists}
        />
      </div>
      <div>
        {isViewConversationVisible === true &&
          <ViewConversationComponent
            promotion={viewConversationPromotion}
            setIsViewConversationVisible={setIsViewConversationVisible}
            setIsViewPromotionList={setIsViewPromotionList}
          />
        }
      </div>
    </>
  )
}

export default PromotionListPage
