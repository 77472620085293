import React, { useState, useRef, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { LoadScript, Autocomplete, GoogleMap, MarkerF } from '@react-google-maps/api';
import { useGlobalContext } from '../../Context/GlobalContext';
import Papa from 'papaparse';
import  './EditStoreLocationStyles.css';
import { loadGoogleMapsApi } from '../../loadGoogleMapsApi';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Button }from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {postApi}from '../../apiClient';

const containerStyle = {
  width: '50%',
  maxWidth: '160px',
  minWidth: '160px',
  height: '80px'
};

const EditStoreLocationComponent = ({onboardingStoreDetailsData, editStoreType})=> {

  const [editStoreLocation, setEditStoreLocation] = useState([]);
  const [editStoreLocationError, setEditStoreLocationError] = useState(false);
  const [editStoreLocationHelperText, setEditStoreLocationHelperText] = useState('');

  const [autocomplete, setAutocomplete] = useState(null);
  const locationFieldRef = useRef(null);
  const { setLoading, showToast } = useGlobalContext();
  const mapRef = useRef(null);
  const fileInputRef = useRef(null);
  const libraries = ['places'];


  useEffect(() => {
    loadGoogleMapsApi(process.env.REACT_APP_GOOGLE_API_KEY, () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        const input = locationFieldRef.current;
        const autocompleteInstance = new window.google.maps.places.Autocomplete(input);
        autocompleteInstance.setFields(['formatted_address']);
        autocompleteInstance.addListener('place_changed', () => onEditPlaceChanged(autocompleteInstance));
        setAutocomplete(autocompleteInstance);
      } else {
        console.error('Google Maps API is not available.');
      }
    });
  }, []);

  useEffect(()=>{
    if(onboardingStoreDetailsData){
      const lastUpdateStoreLocation =  onboardingStoreDetailsData.locations;
      setEditStoreLocation(lastUpdateStoreLocation);
    }
  },[onboardingStoreDetailsData])

  const onEditPlaceChanged = useCallback((autocompleteInstance) => {
    setLoading(true);
    const place = autocompleteInstance.getPlace();
    if (!place || !place.formatted_address) {
      console.error('Place or formatted address is undefined.');
      setLoading(false);
      return;
    }
    const formattedAddress = place.formatted_address;
    geocodeAddress(formattedAddress)
      .then((coords) => {
        setEditStoreLocation((prevStoreLocation) => [
          ...prevStoreLocation,
          { coords, formattedAddress }
        ]);
      })
      .catch((error) => {
        console.error('Error fetching location:', error);
      })
      .finally(() => {
        setLoading(false);
        if (locationFieldRef.current) {
          locationFieldRef.current.value = '';
        }
      });
  }, []);

  const geocodeAddress = async (address) => {
    const geoResponse = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
    const geoData = await geoResponse.json();
    if (geoData.results.length > 0) {
      return geoData.results[0].geometry.location;
    } else {
      console.log('Something went wrong');
    }
  };

  const handleLocationDelete = (locationoDelete) => () => {
    setEditStoreLocation((currentLocations) => currentLocations.filter((loaction) => loaction !== locationoDelete));
  };

  const mapOptions = {
    fullscreenControl: true,
  };

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;

    // Apply custom styles to the fullscreen control
    const fullscreenControl = map.controls[window.google.maps.ControlPosition.RIGHT_TOP].getArray()[0];
    if (fullscreenControl) {
      fullscreenControl.style.width = '140px';
      fullscreenControl.style.height = '140px';

      const img = fullscreenControl.querySelector('img');
      if (img) {
        img.style.width = '100%';
        img.style.height = '100%';
      }
    }
  }, []);

  const handleEditLocationFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async (results) => {
          const parsedAddresses = results.data;
          const addressArray = [];
          const keyToCheck = 'Address';
          const someObjectsContainKey = parsedAddresses.some(obj => obj.hasOwnProperty(keyToCheck));
          if(someObjectsContainKey){
            setLoading(true);
            for (const resData of parsedAddresses) {
              if (resData.Address !== '') {
                try {
                  const coords = await geocodeAddress(resData.Address);
                  const formattedAddress = resData.Address;
                  addressArray.push({ coords, formattedAddress });
                  event.target.value = null;
                } catch (error) {
                  event.target.value = null;
                  console.error('Error fetching location:', error);
                }
              }
            }
            setEditStoreLocation([...editStoreLocation, ...addressArray])
            setLoading(false);
          }else{
            event.target.value = null;
            showToast('Please upload a right .csv file that you can download from Download Template', 'error');
          }
        },
        error: (error) => {
          console.error('Error parsing CSV file:', error);
        },
      });
    }
  }

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleEditLocationType = async () => {

    try{
      setLoading(true);
      let valid = true;
      if (editStoreLocation.length < 1) {
        setEditStoreLocation(true);
        setEditStoreLocationHelperText('Please choose the store location');
        valid = false;
        setLoading(false);
      } else {
        setEditStoreLocationError(false);
        setEditStoreLocationHelperText('');
      }
      // if(haveRetailStoreLocation !== 'Both'){
      //   setBothStoreType('');
      // }

      if(valid){
        let editStoreLocationData = {
          storeId:localStorage.getItem("storeId"),
          locations:editStoreLocation,
          isStoreRetail:onboardingStoreDetailsData.isStoreRetail,
          idealOutcomeWithPushsend: onboardingStoreDetailsData.idealOutcomeWithPushsend
        }

        let response = await postApi('api/update-store-details',editStoreLocationData)
        const { message} = response.data;
        if (response.status === 200 && message) {
          showToast(message, 'success');
          setLoading(false);
        }
      }

    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }

  }

  return (
      <div className='editStoreLocationformContainer'>
        <div className='editStoreLocationWrapper'>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={7} className='editStorelocationInfo'>You can add multiple locations</Grid>
              <Grid item xs={12} md={5} className='editStoreLocationdownloadTemplateInfo'><Link className='editStoreLocationdownloadTemplate'><img src={process.env.REACT_APP_URL+'download-minimalistic.svg'} alt='download csv template' className='mr-1'/>Download CSV Template</Link></Grid> 
          </Grid>
          
          <form className='editStoreLocationForm' noValidate>
              <FormControl component="fieldset">
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="shopLocation"
                  label="Add location by store name or address"
                  placeholder='Add location by store name or address'
                  name="shopLocation"
                  autoComplete="shopLocation"
                  autoFocus
                  inputRef={locationFieldRef}
                  error={editStoreLocationError}
                  helperText={editStoreLocationHelperText}
                />
              </FormControl>
              
              <div className='editStoreLocationOr'>OR</div>
              <div className="editStoreLocationFileUpload" onClick={handleClick}>
                <input
                  accept=".csv"
                  className="editStoreLocationFileInput"
                  id="file-upload"
                  type="file"
                  onChange={handleEditLocationFileUpload}
                  ref={fileInputRef}
                />
                <label className="editStoreLocationFileUploadLabel">
                <img src={process.env.REACT_APP_URL+'upload-minimalistic.svg'} alt='upload loaction csv' className='mr-1'/>{"Upload CSV File"}
                </label>
              </div>
              <div className='editStoreLocationListStyle'>
                {Array.isArray(editStoreLocation) && editStoreLocation?.map((sLocation, index) => (
                  <div className='editStoreLocationChip' key={index}>
                    <div className='editStoremapAndLocation'>
                      {/* <img src={process.env.REACT_APP_URL+'Map-382.png'} alt='map' className='imgMap'/> */}
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={ sLocation.coords || { lat: 0, lng: 0 }} // Default center if location is not yet available
                        zoom={sLocation.coords ? 15 : 2} // Default zoom level
                        options={mapOptions}
                        onLoad={handleMapLoad}
                      >
                        <MarkerF position={sLocation.coords} />
                      </GoogleMap>
                      {sLocation.formattedAddress}
                    </div>
                    
                    <div className='editStoreCrossOnLocation' onClick={handleLocationDelete(sLocation)}>
                      <img src={process.env.REACT_APP_URL+'Cross-1171276068.svg'} alt='cross'/>
                    </div>
                  </div>
                ))}
              </div>
          </form>
        </div>
        <div className='editStoreLocationButton'>
          <Button
            onClick={()=> handleEditLocationType()}
            variant="primary"
            fullWidth
            disabled={editStoreType === "Online Stores"?true:false}
          >
            Update
            <img
              src={process.env.REACT_APP_URL+"next-long-arrow-right.svg"}
              alt="next"
            />
          </Button>
        </div>
      </div>
      
  )
}

 export default EditStoreLocationComponent
