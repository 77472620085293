import React from 'react';
import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import config from '../../Config'
import  './ResetPasswordPageStyles.css';
import ResetPasswordContainer from '../../Containers/ResetPasswordContainer/ResetPasswordContainer'

function ResetPassword() {
  return (
    <Grid container>
      <Grid item xs={5} className='resetImgBackground'>
          <div className="flex items-center justify-center min-h-screen flex-col">
            <div className="w-358">
            <img src={config.baseURL+"login-banner.gif"}
              alt='loginlogo'
              className="w-200"
            />
            </div>
            <div className="mt-2.5 px-24">
              <p className="text-white text-center text-3xl font-medium leading-normal">The Customer Conversion <br></br>and Data Insights Platform</p>
            </div>
          </div>
          
      </Grid>
      <Grid item xs={7}>
        <div className="resetContainer">
          <div className='resetLogoDiv'> 
            <img src={config.baseURL+"pushsend-logo.png" } 
            className='resetLogo'
            alt='loginlogo'
            />
          </div>
          <CssBaseline />
          <div className="resetFormWrapper" elevation={3}>
              <p className="resetHeading" >
              Reset your password
              </p>
              <p className="text-custom-gray text-14px leading-normal mt-3">
              Please enter your details
              </p>
              <ResetPasswordContainer/>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;

