import React from 'react';
import  './DashboradPageStyles.css';
import { Link } from 'react-router-dom';

const DashboradPage = () => {

  const storeExistOrNot = localStorage.getItem('storeExists');
  return (
    <div className='dashboardPageWrapper'>
      <div className='dashboardPageContainer'>
        {storeExistOrNot === "false" ?
          <>
            <p className="dashboardPageTitle">Hello! CP</p>
            <p className="text-gray-600 text-[20px] not-italic font-normal leading-normal"> Sync your Customer Data and Get Started Today.</p>
          </>
          :
          <>
            <p className="dashboardPageTitle">Congratulations!!! </p>
            <p className="text-gray-600 text-[20px] not-italic font-normal leading-normal">Your store has been onboarded on our platform. Now you can create your promotions from here.</p>
          </>
        }
        {/* <img src={process.env.REACT_APP_URL+'Rectangle 384.png'} className='mt-8'/> */}
        <img src={process.env.REACT_APP_URL+'Rectangle 384.png'} alt="Rectangle" className='dashboardImage'/>
        {storeExistOrNot === "false" ?
          <Link to='/app/onboarding'>
            <div className='dashBoardPageButton'>
              <p className='dashboardButtonText'>Lets get Started</p>
            </div>
          </Link>
        :
          <Link to='/app/create-promotion'>
            <div className='dashBoardPageButton'>
              <p className='dashboardButtonText'>Create Your Promotion</p>
            </div>
          </Link>
        }
      </div> 
      
    </div>
  )
}

export default DashboradPage
