import React, {useState, useEffect} from 'react';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ScrollToTop from '../../Layout/ScrollToTop';

import {postApi, getAll}from '../../apiClient';

import  './StoreDetailsStyles.css';
import { Grid } from '@mui/material';

const StoreDetailsComponent = ({
  category, setCategory,
  categoryError,
  categoryHelperText,
  subcategory,
  subcategoryError,
  setSubcategory,
  subcategoryHelperText,
  keyAudience,
  setKeyAudience,
  keyAudienceError,
  keyAudienceHelperText,
  overallContentTone,
  setOverallContentTone,
  overallContentToneError,
  overallContentToneHelperText,
  keyTarget,
  setKeyTarget,
  keyTargetError,
  keyTargetHelperText,
  storeDescription,
  setStoreDescription,
  storeDescriptionError,
  storeDescriptionHelperText,
  sliderValue,
  setSliderValue,
  digitalPromotionPerYear,
  setDigitalPromotionPerYear,
  digitalPromotionPerYearError,
  digitalPromotionPerYearHelperText,
  offerDiscount,
  setOfferDiscount,
  offerDiscountError,
  offerDiscountHelperText,
  setInstractionToTrain,
  instractionToTrain
})=> {
  
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [contentToneList, setContentToneList] = useState([]);
  const [keyAudienceList, setKeyAudienceList] = useState([]);
  const [keyTargetsList, setKeyTargetsList] = useState([]);
  // const handleSliderChange = (event, newValue) => {
  //   setSliderValue(newValue);
  // };

  useEffect(() => {
    getCategory();
    getOthersAttribute();
  }, []);

  const getCategory = async () => {
    let response = await getAll('api/category')
    const { category } = response.data;
    if (response.status === 200 && category.length>0) {
      setCategoryList(category)
    }
  }

  const categoryHandleChnage = async (value) => {
    setCategory(value)
    getSubcategory(value)
  }

  const getSubcategory=async (value) => {
    let params = {
      categoryName : [value]
    }
    let response = await postApi('api/sub-category',params)
    const { category } = response.data;
    if (response.status === 200 && category.length>0) {
      setSubCategoryList(category)
    }
  }

  const getOthersAttribute = async () => {
    let response = await getAll('api/default-attribute')
    const { contentTone, keyAudience, keyTargets } = response.data;
    if (response.status === 200 && contentTone.length>0 && keyAudience.length>0 && keyTargets.length>0) {
      setKeyAudienceList(keyAudience)
      setContentToneList(contentTone)
      setKeyTargetsList(keyTargets)
    }
  }

  const isSelected = (value) => overallContentTone.includes(value);
  return (
    <>
    <ScrollToTop/>
    <div className='formContainerFourStep'> 
        <p className='headingFirstStep'>Store details</p>
        <span className='locationInfo'>Tell us about your store</span>
        <form className='storeDetailForm' noValidate>
          <FormControl variant="outlined" margin="normal">
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              label="Category"
              required
              name="category"
              value={category}
              onChange={(e) => categoryHandleChnage(e.target.value)}
              error={categoryError}
            >
              {categoryList.map((cat, index) => 
              <MenuItem value={cat.categoryId}>{cat.categoryName}</MenuItem>
              )}
            </Select>
            <FormHelperText>{categoryHelperText}</FormHelperText>
          </FormControl>

          <FormControl variant="outlined" margin="normal">
            <InputLabel id="subcategory-label">Sub Category</InputLabel>
            {/* <Select
              labelId="subcategory-label"
              id="subcategory"
              label="Sub Category"
              required
              name="subcategory"
              value={subcategory}
              onChange={(e) => setSubcategory(e.target.value)}
              error={subcategoryError}
            >
              {subCategoryList.map((subcat, index) => 
                <MenuItem value={subcat.categoryId}>{subcat.categoryName}</MenuItem>
              )}
            </Select> */}
              <Select
                  multiple
                  labelId="subcategory-label"
                  id="subcategory"
                  label="Sub Category"
                  required
                  name="subcategory"
                  value={subcategory}
                  onChange={(e) => setSubcategory(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                  error={subcategoryError}
                >
                  {subCategoryList.map((subcat, index) => 
                    <MenuItem key={index} value={subcat.categoryName}>
                      <Checkbox checked={subcategory.indexOf(subcat.categoryName) > -1} />
                      <ListItemText primary={subcat.categoryName} />
                    </MenuItem>
                  )}
                </Select>
            <FormHelperText>{subcategoryHelperText}</FormHelperText>
          </FormControl>

          <FormControl variant="outlined" margin="normal">
            <InputLabel id="role-label">Key Audience</InputLabel>
            <Select
              labelId="key-audience-label"
              id="keyAudience"
              label="Key Audience"
              required
              name="keyAudience"
              value={keyAudience}
              onChange={(e) => setKeyAudience(e.target.value)}
              error={keyAudienceError}
            >
              {keyAudienceList.map((keyAud, index) => 
              //  <>
              //  <MenuItem value={keyAud.keyAudience}>{keyAud.keyAudience}</MenuItem> 
              // </>
              <MenuItem key={keyAud.keyAudience} value={keyAud.keyAudience}>
                <ListItemText 
                  primary={keyAud.keyAudience}  
                  secondary={<Typography variant="body2" sx={{fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43'}}>Years born : {keyAud.bornYear}</Typography>}
                />
              </MenuItem>
              )}
             
            </Select>
            <FormHelperText>{keyAudienceHelperText}</FormHelperText>
          </FormControl>

          <FormControl variant="outlined" margin="normal">
            <InputLabel id="conetent-tone-label">Overall Content Tone</InputLabel>
            <Select
              multiple
              labelId="conetent-tone-label"
              id="contentTone"
              label="Overall Content Tone"
              name="overallContentTone"
              value={overallContentTone}
              onChange={(e) => setOverallContentTone(e.target.value)}
              renderValue={(selected) => selected.join(', ')}
              error={overallContentToneError}
            >
              {contentToneList.map((tone, index) => 
              // <MenuItem value={tone}>{tone}</MenuItem>
                <MenuItem key={index} value={tone}>
                  <Checkbox checked={isSelected(tone)} />
                  <ListItemText primary={tone} />
                </MenuItem>
              )}
            </Select>
            <FormHelperText>{overallContentToneHelperText}</FormHelperText>
          </FormControl>
            
          <FormControl variant="outlined" margin="normal">
            <InputLabel id="key-target-label">Key Target</InputLabel>
            <Select
              labelId="key-target-label"
              id="keyTarget"
              label="Key Target"
              name="keyTarget"
              value={keyTarget}
              onChange={(e) => setKeyTarget(e.target.value)}
              error={keyTargetError}
            >
              {keyTargetsList.map((keyTar, index) => 
              <MenuItem value={keyTar}>{keyTar}</MenuItem>
              )}
            </Select>
            <FormHelperText>{keyTargetHelperText}</FormHelperText>
          </FormControl>

          {/* <div className="slider">
            <p id="discrete-slider" className='text-[12px] text-custom-gray'>
              Discount Promos
            </p>
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              // step={10}
              // marks
              min={0}
              max={100}
              classes={{
                thumb: 'MuiSlider-thumb',
                track: 'MuiSlider-track',
                rail: 'MuiSlider-rail',
              }}
            />
          </div> */}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="storeDescription"
            label="Store Description"
            name="storeDescription"
            autoComplete="storeDescription"
            autoFocus
            multiline
            rows={4}
            value={storeDescription}
            onChange={(e) => setStoreDescription(e.target.value)}
            error={storeDescriptionError}
            helperText={storeDescriptionHelperText}
          />
          <p className='mt-5 mb-5 font-bold'>Promotion Details</p>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <p variant="body1" className='text-custom-gray text-[12px]'>How many Digital Promotion do you run per year?</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Please enter your details*"
                name="digitalPromotionPerYear"
                type="number"
                variant="outlined"
                fullWidth
                value={digitalPromotionPerYear}
                onChange={(e) => setDigitalPromotionPerYear(e.target.value)}
                error={digitalPromotionPerYearError}
                helperText={digitalPromotionPerYearHelperText}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p variant="body1" className='text-custom-gray text-[12px]'>What percentage of those do you offer discounts?</p>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <TextField
                label="Please enter you details*"
                name="offerDiscount"
                type="text"
                variant="outlined"
                fullWidth
                value={offerDiscount}
                onChange={(e) => setOfferDiscount(e.target.value)}
                error={offerDiscountError}
                helperText={offerDiscountHelperText}
              /> */}
              <FormControl variant="outlined" margin="normal" className='w-full'>
                <InputLabel id="percentage-offer-discount-label">Choose your percentage</InputLabel>
                <Select
                  labelId="percentage-offer-discount-label"
                  id="offerDiscount"
                  label="Choose your percentage"
                  name="offerDiscount"
                  value={offerDiscount}
                  onChange={(e) => setOfferDiscount(e.target.value)}
                  error={offerDiscountError}
                >
                  <MenuItem value='0%'>0%</MenuItem>
                  <MenuItem value='5%'>5%</MenuItem>
                  <MenuItem value='10%'>10%</MenuItem>
                  <MenuItem value='15%'>15%</MenuItem>
                  <MenuItem value='20%'>20%</MenuItem>
                  <MenuItem value='25%'>25%</MenuItem>
                  <MenuItem value='30%'>30%</MenuItem>
                </Select>
                <FormHelperText>{offerDiscountHelperText}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
                <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="instractionToTrain"
                label="Please provide us any special instruction to help better train PushSend Ai"
                name="instractionToTrain"
                autoComplete="instractionToTrain"
                autoFocus
                multiline
                rows={4}
                value={instractionToTrain}
                onChange={(e) => setInstractionToTrain(e.target.value)}
                // error={instractionToTrainError}
                // helperText={instractionToTrainHelperText}
              />
            </Grid>
          </Grid>
        </form>
    </div>
    </>
  )
}

 export default StoreDetailsComponent
