import React, {useState} from 'react';
import  './ChooseModeStyles.css';
import Badge from '@mui/material/Badge';
import ScrollToTop from '../../Layout/ScrollToTop';

const ChooseModeComponent = ({
  chooseMode, 
  setChooseMode,
  chooseModeError,
  chooseModeHelperText
})=> {

const chooseModeChange = (event) => {
    setChooseMode(event.target.value);
};
  return (

    <div>
      <ScrollToTop/>
      <p className='headingSixStep'>Choose a Mode</p>
       <p className='subHeadingSixStep'>Don't worry, you can always switch between modes.</p>
        <div className='formContainerSixStep'>
          <div className={` ${chooseMode === 'Hands-On' ? 'customRadioSelectedSix' : 'chooseModeBack'}`} onClick={() => setChooseMode('Hands-On')}>
            <div className='customRadioSix'>
              <img
                src={chooseMode === 'Hands-On' ? process.env.REACT_APP_URL+'Radio-select.svg' : process.env.REACT_APP_URL+'Radio-unselect.svg'}
                alt="Female"
                className="pl-5 mr-4 w-10 h-10"
              />
              <p className="text-[22px] not-italic font-medium leading-normal text-[#000]">Hands-On</p>
            </div>
            <p className='handsonContent'>Time and/or having marketing members focus is not an issue? Or not sure you want to let us do all the work just yet? No problem choose Hands-On and you will be in the drivers seat to create campaigns, approve conversations and ensure the tone and language is just right for every conversation we generate.</p>
          </div>
          <Badge badgeContent="Coming Soon" color="secondary" className="custom-badge">
            <div className={` ${chooseMode === 'Auto Pilot' ? 'customRadioSelectedSix' : 'chooseModeBack'}`} onClick={() => setChooseMode('Auto Pilot')}>
              <div className='customRadioSix'>
                <img
                  src={chooseMode === 'Auto Pilot' ? process.env.REACT_APP_URL+'Radio-select.svg' : process.env.REACT_APP_URL+'Radio-unselect.svg'}
                  alt="Female"
                  className="pl-5 mr-4 w-10 h-10"
                />
                <p className="text-[22px] not-italic font-medium leading-normal text-[#000]">Auto Pilot</p>   
              </div>
              <p className='handsonContent'>Don't have the time or team to handle writing one-to-one customer conversations and approving all created campaigns? Or want to see what PushSend Al can do with your hands of the marketing wheel? Great! Then set on Auto Pilot andiet PushSend Al do all the woric. Don't work we only work within parameters you set and based on customer data.</p>
            </div>
          </Badge>
        </div> 
        {
          chooseModeError === true ? <p className='text-[#d32f2f] text-[12px] not-italic font-normal leading-normal font-dm-sans '>{chooseModeHelperText}</p>:null
        }
    </div> 
  )
}

 export default ChooseModeComponent
