import React from 'react'
import ForgotPasswordComponent from '../../Components/ForgotPasswordComponent/ForgotPasswordComponent'

const ForgotPasswordContainer = () => {
  return (
    <><ForgotPasswordComponent/></>
  )
}

export default ForgotPasswordContainer
