import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { useGlobalContext } from '../../Context/GlobalContext';

import './EditContentPromoDetailsStyles.css';

import { postApi } from '../../apiClient';

const EditContentPromoDetailsComponent = ({onboardingStoreDetailsData}) => {

  const [editKeyIncludes, setEditKeyIncludes] = useState([]);
  const [editKeyIncludesError, setEditKeyIncludesError] = useState(false);
  const [editKeyIncludesHelperText, setEditKeyIncludesHelperText] = useState('');

  const [editKeyIncludesSecond, setEditKeyIncludesSecond] = useState([]);
  const [editKeyIncludesSecondError, setEditKeyIncludesSecondError] = useState(false);
  const [editKeyIncludesSecondHelperText, setEditKeyIncludesSecondHelperText] = useState('');

  const [editMaximumConversion, setEditMaximumConversion] = useState('');
  const [editMaximumConversionError, setEditMaximumConversionError] = useState(false);
  const [editMaximumConversionHelperText, setEditMaximumConversionHelperText] = useState('');

  const [editMajorHolidays, setEditMajorHolidays] = useState('');

  const [editGeneralEvents, setEditGeneralEvents] = useState('');

  const [editAbandonedCart, setEditAbandonedCart] = useState('');

  const [editFirstTimeBuyer, setEditFirstTimeBuyer] = useState('');

  const [editLoyalCustomer, setEditLoyalCustomer] = useState('');

  const [editMaxOffBirthday, setEditMaxOffBirthday] = useState('');

  const [editGeneralPromotion, setEditGeneralPromotion] = useState('');

  const [editMaxOffFollowups, setEditMaxOffFollowups] = useState('');

  const [editViewedTwoTimes, setEditViewedTwoTimes] = useState('');

  const [checkboxValue, setCheckboxValue] = useState(false);

  const [promotionSelectedDiv, setPromotionSelectedDiv] = useState('content');

  const { setLoading, showToast } = useGlobalContext();

  useEffect(()=>{
    if(onboardingStoreDetailsData){
      const lastKeyIncludeDetails =  onboardingStoreDetailsData?.contentDetails?.keyIncludes;
      const lastKeyIncludeSecond =  onboardingStoreDetailsData?.contentDetails?.keyIncludesSecond;
      const lastMaximumConversion =  onboardingStoreDetailsData?.contentDetails?.maximumConversion;
      const lastAbandonedCartPercentage =  onboardingStoreDetailsData?.promoDetails?.abandonedCartPercentage;
      const lastFirstTimeBuyerPercentage =  onboardingStoreDetailsData?.promoDetails?.firstTimeBuyerPercentage;
      const lastGeneralEventsPercentage =  onboardingStoreDetailsData?.promoDetails?.generalEventsPercentage;
      const lastGeneralPromotionPercentage =  onboardingStoreDetailsData?.promoDetails?.generalPromotionPercentage;
      const lastLoyalCustomerPercentage =  onboardingStoreDetailsData?.promoDetails?.loyalCustomerPercentage;
      const lastMajorHolidaysPercentage=  onboardingStoreDetailsData?.promoDetails?.majorHolidaysPercentage;
      const lastMaxOffBirthdayPercentage =  onboardingStoreDetailsData?.promoDetails?.maxOffBirthdayPercentage;
      const lastMaxOffFollowupsPercentage =  onboardingStoreDetailsData?.promoDetails?.maxOffFollowupsPercentage;
      const lastViewedTwoTimesPercentage =  onboardingStoreDetailsData?.promoDetails?.viewedTwoTimesPercentage;
      setEditKeyIncludes(lastKeyIncludeDetails);
      setEditKeyIncludesSecond(lastKeyIncludeSecond);
      setEditMaximumConversion(lastMaximumConversion);
      setEditMajorHolidays(lastMajorHolidaysPercentage);
      setEditGeneralEvents(lastGeneralEventsPercentage);
      setEditAbandonedCart(lastAbandonedCartPercentage);
      setEditFirstTimeBuyer(lastFirstTimeBuyerPercentage);
      setEditLoyalCustomer(lastLoyalCustomerPercentage);
      setEditMaxOffBirthday(lastMaxOffBirthdayPercentage);
      setEditGeneralPromotion(lastGeneralPromotionPercentage);
      setEditMaxOffFollowups(lastMaxOffFollowupsPercentage);
      setEditViewedTwoTimes(lastViewedTwoTimesPercentage);
    }
  },[onboardingStoreDetailsData])

  const handleDivClickForPromotion = (tab) => {
    setPromotionSelectedDiv(tab);
  };

  const handleEditContentTagAddition = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      const newTag = event.target.value.trim();
      if (!editKeyIncludes.includes(newTag)) {
        setEditKeyIncludes([...editKeyIncludes, newTag]);
        event.target.value = '';
      }
    }
  };

  const handleEditContentKeywordExclude = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      const neweTag = event.target.value.trim();
      if (!editKeyIncludesSecond.includes(neweTag)) {
        setEditKeyIncludesSecond([...editKeyIncludesSecond, neweTag]);
        event.target.value = '';
      }
    }
  };

  const handleEditContentTagDelete = (tagToDelete) => () => {
    setEditKeyIncludes((currentTags) => currentTags.filter((tag) => tag !== tagToDelete));
  };

  const handleEditContentExkeywordsDelete = (keyToDelete) => () => {
    setEditKeyIncludesSecond((currTags) => currTags.filter((extag) => extag !== keyToDelete));
  };

  const handleEditContentCheckboxChange = (event) => {
    setCheckboxValue(event.target.checked);
  };

  const handleEditContentAndPromoDetails = async () => {
    try {
      //e.preventDefault();
      setLoading(true);
      let valid = true;
      if (editKeyIncludes.length < 1) {
        setEditKeyIncludesError(true);
        setEditKeyIncludesHelperText('Please enter keywords');
        valid = false;
        setLoading(false);
      } else {
        setEditKeyIncludesError(false);
        setEditKeyIncludesHelperText('');
      }

      if (editKeyIncludesSecond.length < 1) {
        setEditKeyIncludesSecondError(true);
        setEditKeyIncludesSecondHelperText('Please enter keywords');
        valid = false;
        setLoading(false);
      } else {
        setEditKeyIncludesSecondError(false);
        setEditKeyIncludesSecondHelperText('');
      }

      if (!editMaximumConversion) {
        setEditMaximumConversionError(true);
        setEditMaximumConversionHelperText('Please enter maximum conversion per month');
        valid = false;
        setLoading(false);
      } else {
        setEditMaximumConversionError(false);
        setEditMaximumConversionHelperText('');
      }

      if (valid) {
        let editContentAndPromoData = {
          storeId: localStorage.getItem("storeId"),
          contentDetails: {
            keyIncludes: editKeyIncludes,
            keyIncludesSecond: editKeyIncludesSecond,
            maximumConversion: editMaximumConversion
          },
          promoDetails: {
            majorHolidaysPercentage: editMajorHolidays,
            generalEventsPercentage: editGeneralEvents,
            abandonedCartPercentage: editAbandonedCart,
            firstTimeBuyerPercentage: editFirstTimeBuyer,
            loyalCustomerPercentage: editLoyalCustomer,
            maxOffBirthdayPercentage: editMaxOffBirthday,
            generalPromotionPercentage: editGeneralPromotion,
            maxOffFollowupsPercentage: editMaxOffFollowups,
            viewedTwoTimesPercentage: editViewedTwoTimes
          }
        }

        let response = await postApi('api/update-store-content', editContentAndPromoData)
        const { message } = response.data;
        if (response.status === 200 && message) {
          showToast(message, 'success');
          setLoading(false);
        }
      }

    } catch (error) {
      setLoading(false);
      showToast(error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error", error)
    }

  }

  return (
    <div>
      <div>
        <div className='editContentPromoButton'>
          <div className={promotionSelectedDiv === "content" ? 'editContentdetailsSelected' : 'editContentdetails'} onClick={() => handleDivClickForPromotion('content')}>
            <p className='editContentText'>Content Details</p>
          </div>
          <div className={promotionSelectedDiv === "promo" ? 'editContentdetailsSelected' : 'editContentdetails'} onClick={() => handleDivClickForPromotion('promo')}>
            <p className='editContentText'>Promo Details</p>
          </div>
          <div className='editContentAndPromoDetailsButton'>
            <Button
              onClick={() => handleEditContentAndPromoDetails()}
              variant="primary"
              fullWidth
              sx={{ height: '33px !important' }}
            >
              Update
              <img
                src={process.env.REACT_APP_URL + "next-long-arrow-right.svg"}
                alt="next"
              />
            </Button>
          </div>
        </div>

        <div className='editContentDetailsFormContainer'>
          <div className='editContentDetailsForm'>
            {
              promotionSelectedDiv === "content" ?
                <form className='contentPromoDetailsForm' noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    placeholder='Add keywords to include'
                    label=" Keywords to Include"
                    onKeyDown={handleEditContentTagAddition}
                    name='editKeyIncludes'
                    error={editKeyIncludesError}
                    helperText={editKeyIncludesHelperText}

                  />
                  {editKeyIncludes?.map((tag, index) => (

                    <Chip
                      key={index}
                      label={tag}
                      onDelete={handleEditContentTagDelete(tag)}
                      color="primary"
                      variant="outlined"
                      size="small"
                      deleteIcon={<CloseIcon />}
                      style={{ margin: '4px' }}
                    />
                  ))}

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="keywordsToExclude"
                    placeholder='Add keywords to exclude'
                    label=" Keywords to Exclude"
                    type="text"
                    id="exKeywords"
                    autoComplete="exKeywords"
                    onKeyDown={handleEditContentKeywordExclude}
                    error={editKeyIncludesSecondError}
                    helperText={editKeyIncludesSecondHelperText}

                  />

                  {editKeyIncludesSecond?.map((extag, idx) => (

                    <Chip
                      key={idx}
                      label={extag}
                      onDelete={handleEditContentExkeywordsDelete(extag)}
                      color="primary"
                      variant="outlined"
                      size="small"
                      deleteIcon={<CloseIcon />}
                      style={{ margin: '4px' }}
                    />
                  ))}

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={8}>
                      <p variant="body1" className='editPromoContentText'>Maximum conversion sent per month (to each customer)</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        name="editMaximumConversion"
                        variant="outlined"
                        fullWidth
                        value={editMaximumConversion}
                        onChange={(e) => setEditMaximumConversion(e.target.value)}
                        error={editMaximumConversionError}
                        helperText={editMaximumConversionHelperText}
                      />
                    </Grid>
                  </Grid>
                  <Box display="flex" justifyContent="flex-end">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxValue}
                          onChange={handleEditContentCheckboxChange}
                          color="primary"
                          className='flex align-item-end'
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 18, // Control the size of the Checkbox
                              color: '#646464', // Default color
                            },
                            '&.Mui-checked .MuiSvgIcon-root': {
                              color: 'rgb(150 98 255)', // Change color when checked
                            },
                          }}
                        />
                      }
                      label="Allow system to decide"
                    />
                  </Box>
                </form>
                :
                <form className="form">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off for Major Holidays/Events </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="majorHoliday"
                        variant="outlined"
                        fullWidth
                        value={editMajorHolidays}
                        onChange={(e) => setEditMajorHolidays(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off for general promotions <br />(anything not a major holiday or general event)</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="editGeneralPromotion"
                        variant="outlined"
                        fullWidth
                        value={editGeneralPromotion}
                        onChange={(e) => setEditGeneralPromotion(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off for General Events</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="editGeneralEvents"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={editGeneralEvents}
                        onChange={(e) => setEditGeneralEvents(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off for abandoned cart items</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="editAbandonedCart"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={editAbandonedCart}
                        onChange={(e) => setEditAbandonedCart(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off for first time buyer (for 2nd purchase)</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="editFirstTimeBuyer"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={editFirstTimeBuyer}
                        onChange={(e) => setEditFirstTimeBuyer(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off for Loyal Customers (shopped 5 or more times)</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="editLoyalCustomer"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={editLoyalCustomer}
                        onChange={(e) => setEditLoyalCustomer(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off for Birthdays</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="birthday"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={editMaxOffBirthday}
                        onChange={(e) => setEditMaxOffBirthday(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off for Follow Ups</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="followups"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={editMaxOffFollowups}
                        onChange={(e) => setEditMaxOffFollowups(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p variant="body1" className='promoContentText'>Provide the max % off if viewed a product at least 2 times</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="%"
                        name="editViewedTwoTimes"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={editViewedTwoTimes}
                        onChange={(e) => setEditViewedTwoTimes(e.target.value)}
                      />
                    </Grid>

                  </Grid>
                </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditContentPromoDetailsComponent
