import React from 'react';

const Loader = () => (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-25 z-50"></div>
      
      {/* Loader */}
      {/* bg-white shadow-lg */}
      <div className="w-12 h-12 border-t-4 border-blue-500 rounded-full animate-spin z-50">
      </div>
    </div>
  );
  
  export default Loader;