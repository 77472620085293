import React from 'react'
import VerifyEmailComponent from '../../Components/VerifyEmailComponent/VerifyEmailComponent'

const VerifyEmailContainer = () => {
  return (
    <><VerifyEmailComponent/></>
  )
}

export default VerifyEmailContainer
