import React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import CustomerListSidebar from '../CustomerListSideBarComponent/CustomerListSidebar';
import "./InboxMessageContent.css";

const InboxMessageContent = () => {
    const [selectedTab, setSelectedTab] = useState('SMS');

    const handleButtonClick = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <>
            <div className='inboxContainer'>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={3} mx={1}>
                        <CustomerListSidebar />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} >
                        <div className='promotionContainer'>
                            <div className="buttonContainer">
                                <div
                                    onClick={() => handleButtonClick('SMS')}
                                    className={selectedTab === "SMS" ? 'activeTabButton' : 'inactiveTabButton'}
                                >
                                    SMS
                                </div>
                                <div
                                    onClick={() => handleButtonClick('Email')}
                                    className={selectedTab === "Email" ? 'activeTabButton' : 'inactiveTabButton'}
                                >
                                    Email
                                </div>
                                <div
                                    onClick={() => handleButtonClick('Whatsapp')}
                                    className={selectedTab === "Whatsapp" ? 'activeTabButton' : 'inactiveTabButton'}
                                >
                                    Whatsapp
                                </div>
                            </div>
                            <div className='containerwrapper'>
                                <div className="promotionList">
                                    <div className="promotionItem">
                                        <div className="promotionDateContainer">
                                            <div className="promotionDate">Jun 2024</div>
                                        </div>
                                        <div className="promotionItemContainer">
                                            <div className="promotionTitle">Promotion 1</div>
                                            <p className="promotionDescription">
                                                {selectedTab} Get ready for Father's Day with our sophisticated men's collection
                                                at Pushsendclothing. Elevate your style game with Exclusive apparel &
                                                accessories. Use code 'DAD12LOVE' for a touch of Luxury. Shop now at
                                                Pushsendclothing. Event starts 2024-06-01
                                            </p>
                                        </div>
                                    </div>
                                    <div className="promotionItem">
                                        <div className="promotionDateContainer">
                                            <div className="promotionDate">Jun 2024</div>
                                        </div>
                                        <div className="promotionItemContainer">
                                            <div className="promotionTitle">Promotion 2</div>
                                            <p className="promotionDescription">
                                                {selectedTab} Get ready for Father's Day with our sophisticated men's collection
                                                at Pushsendclothing. Elevate your style game with Exclusive apparel &
                                                accessories. Use code 'DAD12LOVE' for a touch of Luxury. Shop now at
                                                Pushsendclothing. Event starts 2024-06-01
                                            </p>
                                        </div>
                                    </div>
                                    <div className="promotionItem">
                                        <div className="promotionDateContainer">
                                            <div className="promotionDate">Jun 2024</div>
                                        </div>
                                        <div className="promotionItemContainer">
                                            <div className="promotionTitle">Promotion 1</div>
                                            <p className="promotionDescription">
                                                {selectedTab} Get ready for Father's Day with our sophisticated men's collection
                                                at Pushsendclothing. Elevate your style game with Exclusive apparel &
                                                accessories. Use code 'DAD12LOVE' for a touch of Luxury. Shop now at
                                                Pushsendclothing. Event starts 2024-06-01
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid >
                </Grid >
            </div >
        </>
    )
}

export default InboxMessageContent;
