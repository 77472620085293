import React from 'react'
import ResetPasswordComponent from '../../Components/ResetPasswordComponent/ResetPasswordComponent'

const ResetPasswordContainer = () => {
  return (
    <><ResetPasswordComponent/></>
  )
}

export default ResetPasswordContainer
