import APIPAYMENT from "../ApiClient/ApiClientPayment";
import API from "../ApiClient/ApiClient";
import { 
  CREATEPROMOTION_ROUTES_URL, 
  GET_LIST_SELECT_OPTIONS_ROUTES_URL , 
  GET_CATEGORY_LIST_ROUTES_URL, 
  GET_PROMOTION_LIST_ROUTES_URL,
  GET_PROMOTION_DETAILS_ROUTES_URL,
  EDIT_PROMOTION_ROUTES_URL,
  GET_STORE_DETAILS_ROUTES_URL,
}from "../Constant/Constant";

export function createPromotionApi(params = {}) {
  return API.post(CREATEPROMOTION_ROUTES_URL.CREATE_PROMOTION, params);
}

export function getListSelectOptionsApi(params = {}) {
  return API.get(GET_LIST_SELECT_OPTIONS_ROUTES_URL.GET_LIST_SELECT_OPTIONS, params);
}

export function getPromotionCategoryListApi(params = {}) {
  return API.get(GET_CATEGORY_LIST_ROUTES_URL.GET_PROMOTION_CATEGORY_LIST, params);
}

export function getPromotionListApi(params = "") {
  return API.get(`${GET_PROMOTION_LIST_ROUTES_URL.GET_PROMOTION_LIST}?userId=${params}`);
}

export function getPromotionDetailsApi(params = "") {
  return API.get(`${GET_PROMOTION_DETAILS_ROUTES_URL.GET_PROMOTION_DETAILS}?promotionId=${params}`);
}

export function editPromotionApi(params = "") {
  return API.post(EDIT_PROMOTION_ROUTES_URL.EDIT_PROMOTION_DETAILS,params);
}

export function getStoreDetailsApi(params = "") {
  return API.get(`${GET_STORE_DETAILS_ROUTES_URL.GET_STORE_DETAILS}?email=${params}`);
}
