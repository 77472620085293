import * as React from 'react';
import { Avatar } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../Context/GlobalContext';
import './NavbarStyles.css'; // Import the CSS file

const Navbar = () => {
  const {setHaveRetailStoreLocation, setBothStoreType } = useGlobalContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    setHaveRetailStoreLocation('');
    setBothStoreType('');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('ownerEmail');
    localStorage.removeItem('storeId');
    localStorage.removeItem('fsap');
    localStorage.removeItem('userId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('fullName');
    localStorage.removeItem('storeId');
    navigate('/login');
}

  return (
    <div className='navHeader'>
        <div className='navPosition'>
          <div>
            <p className='navDashboard'> Dashboard</p> 
          </div>
          <div className="flex space-x-6">
            {/* <Badge badgeContent={4} color="primary">
              <NotificationsIcon />
            </Badge> 
            <IconButton>
            <InfoIcon />
            </IconButton> */}
            <img src={process.env.REACT_APP_URL+'notifications_none.svg'} alt='notification'/>
            <img src={process.env.REACT_APP_URL+'info_outline.svg'} alt='info-outline'/>
            <Avatar className="avatar" onClick={handleMenuClick}>CP</Avatar>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              style={{
                  marginTop:'8px',
                  left: '-10px',
              }}
            >
              <MenuItem onClick={logOut}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
    </div>
  );
}

export default Navbar;
