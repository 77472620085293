import React, {useState} from 'react';
import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import  './SubscriptionPlanPageStyles.css';
import SubscriptionPlanContainer from '../../Containers/SubscriptionPlanContainer/SubscriptionPlanContainer';

function SubscriptionPlanPage() {
  
  return (
    <div className='planContainer'>
      <div className='planHeader'>
          <img src={process.env.REACT_APP_URL+'pushsend-logo.png'} className='planPageLogo'/>
          <p className='planPageTitle'>Pricing</p>
          <p className='planPageDescription'>Start genarating hyper-personalized coversation with each of your customers & get them to buy more</p>
      </div>
      
      <SubscriptionPlanContainer/>
    </div>
  );
}

export default SubscriptionPlanPage;

