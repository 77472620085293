import React, { useState } from 'react';
import {
  Button, Modal, TextField, Typography, Container, Box
} from '@mui/material';
import './BusinessModal.css';  // Import the CSS file
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Link } from 'react-router-dom';
import {postApi}from '../../apiClient';

import { useGlobalContext } from '../../Context/GlobalContext';

const ModalForm = ({setBusinessModalOpen, businessModalOpen, handleBusinessModalOpen, handleBusinessModalClose}) => {
    const [storeName, setStoreName] = useState('');
    const [storeNameError, setStoreNameError] = useState(false);
    const [storeNameHelperText, setStoreNameHelperText] = useState('');
    const [storeEmail, setStoreEmail] = useState('');
    const [storeEmailError, setStoreEmailError] = useState(false);
    const [storeEmailHelperText, setStoreEmailHelperText] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyNameHelperText, setCompanyNameHelperText] = useState('');
    const [crmPlatform, setCrmPlatform] = useState('');
    const [crmPlatformError, setCrmPlatformError] = useState(false);
    const [crmPlatformHelperText, setCrmPlatformHelperText] = useState('');
    const [otherCrmPlatform, setOtherCrmPlatform] = useState('');
    const [otherCrmPlatformError, setOtherCrmPlatformError] = useState(false);
    const [otherCrmPlatformHelperText, setOtherCrmPlatformHelperText] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const { setLoading, showToast } = useGlobalContext();
    const [businessSavedSuccesfulModalOpen, setBusinessSavedSuccesfulModalOpen] = useState(false);

    const handleBusinessSavedSuccesfulModalOpen = () => {
      setBusinessSavedSuccesfulModalOpen(true);
    };

    const handleBusinessSavedSuccesfulModalClose = () => {
      setBusinessSavedSuccesfulModalOpen(false);
    };
    
    const validateStoreName = (name) => {
    const nameRegex = /^[a-zA-Z0-9-' ]+$/ ;
    ;
    return nameRegex.test(name);
    };

    const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
    };

    const handleSubmit = async (e) => {
      try{
        e.preventDefault();
        setLoading(true);
        let valid = true;

        if (!validateStoreName(storeName)) {
            setStoreNameError(true);
            setStoreNameHelperText('Please enter your store name by alphabet and digits');
            valid = false;
            setLoading(false);
        } else {
            setStoreNameError(false);
            setStoreNameHelperText('');
        }
        if (!validateEmail(storeEmail)) {
            setStoreEmailError(true);
            setStoreEmailHelperText('Please enter a valid store email address');
            valid = false;
            setLoading(false);
        } else {
            setStoreEmailError(false);
            setStoreEmailHelperText('');
        }
        if (!validateStoreName(companyName)) {
            setCompanyNameError(true);
            setCompanyNameHelperText('Please enter your company name by alphabet and digits');
            valid = false;
            setLoading(false);
        } else {
            setCompanyNameError(false);
            setCompanyNameHelperText('');
        }
        if (!crmPlatform) {
            setCrmPlatformError(true);
            setCrmPlatformHelperText('Please choose the which platform you are using');
            valid = false;
            setLoading(false);
        } else {
            setCrmPlatformError(false);
            setCrmPlatformHelperText('');
        }
        if (!validateStoreName(otherCrmPlatform) && crmPlatform==='Others') {
            setOtherCrmPlatformError(true);
            setOtherCrmPlatformHelperText('Please enter other platform that you are using');
            valid = false;
            setLoading(false);
        } else {
            setOtherCrmPlatformError(false);
            setOtherCrmPlatformHelperText('');
        }
        if (valid) {
          let finisFormData = {
            storeName: storeName,
            storeEmail: storeEmail,
            companyName: companyName,
            crmPlatform: crmPlatform,
            companyWebsite: companyWebsite,
            otherCrmPlatform: otherCrmPlatform
          }
          
          let response = await postApi('api/add-non-offile-store',finisFormData)
          const { message, storeId } = response.data;
          if (storeId) {
            localStorage.setItem('storeId', storeId);
            showToast('Registration successful!', 'success');
            setLoading(false);
            handleBusinessModalClose()
            handleBusinessSavedSuccesfulModalOpen()
            
          }
        }
      }catch (error) {
        setLoading(false);
        showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
        console.log("error",error)
      }
    };

  return (
    <div>
      <Modal open={businessModalOpen} onClose={handleBusinessModalClose} className="modal">
        <div className="paper">
          <div className='headingCrossPosition'>
            <p className='ModalHeading'>
                Tell us about your store
            </p>
            <div className='crossOnModal' onClick={handleBusinessModalClose}>
                <img src={process.env.REACT_APP_URL+'Cross-1171276068.svg'} alt='cross'/>
            </div>
          </div>
          <form  noValidate className="form" onSubmit={handleSubmit}>
            <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder='Please enter your store name'
            id="storeName"
            label="Store Name*"
            name="storeName"
            autoComplete="storeName"
            autoFocus
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
            error={storeNameError}
            helperText={storeNameHelperText}
            />
            <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder='Please enter your store email'
            id="storeEmail"
            label="Store Email*"
            name="storeEmail"
            autoComplete="storeEmail"
            autoFocus
            value={storeEmail}
            onChange={(e) => setStoreEmail(e.target.value)}
            error={storeEmailError}
            helperText={storeEmailHelperText}
            />
            <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder='Please enter your company name'
            id="companyName"
            label="Company Name*"
            name="companyName"
            autoComplete="companyName"
            autoFocus
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            error={companyNameError}
            helperText={companyNameHelperText}
            />
            <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder='Please enter your company website'
            id="companyWebsite"
            label="Comapany website"
            name="companyWebsite"
            autoComplete="companyWebsite"
            autoFocus
            value={companyWebsite}
            onChange={(e) => setCompanyWebsite(e.target.value)}
            // error={companyNameError}
            // helperText={companyNameHelperText}
            />
            <FormControl variant="outlined" margin="normal" style={{width:'100%'}}>
                <InputLabel id="role-label">What platform you are using?*</InputLabel>
                <Select
                labelId="crm-platform"
                id="crmPlatform"
                label="Which CRM platform you are using?*"
                fullWidth
                name="crmPlatform"
                value={crmPlatform}
                onChange={(e) => setCrmPlatform(e.target.value)}
                error={crmPlatformError}
                >
                <MenuItem value='Wix'>Wix</MenuItem>
                <MenuItem value='BigCommerce'>BigCommerce</MenuItem>
                <MenuItem value='Squarespace'>Squarespace</MenuItem>
                <MenuItem value='WooCommerce'>WooCommerce</MenuItem>
                <MenuItem value='Others'>Others</MenuItem>
                </Select>
                <FormHelperText>{crmPlatformHelperText}</FormHelperText>
            </FormControl>
            {crmPlatform === 'Others' && (
              <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="otherCrmPlatform"
              label="Other platform name*"
              name="otherCrmPlatform"
              autoComplete="otherCrmPlatform"
              autoFocus
              value={otherCrmPlatform}
              onChange={(e) => setOtherCrmPlatform(e.target.value)}
              error={otherCrmPlatformError}
              helperText={otherCrmPlatformHelperText}
              />
            )}
            <Button
                type="submit"
                variant="primary"
                fullWidth
            >
                Submit
            </Button>
          </form>
        </div>
      </Modal>
      <Modal open={businessSavedSuccesfulModalOpen} onClose={handleBusinessSavedSuccesfulModalClose} className="modal">
        <div className="paper">
            {/* <div className='crossOnImgModal' onClick={handleBusinessSavedSuccesfulModalClose}>
                <img src={process.env.REACT_APP_URL+'Cross-1171276068.svg'} alt='cross'/>
            </div> */}
            <div className='imgBackGround'>
              <img src={process.env.REACT_APP_URL+"login-banner.gif"}
                alt='signup banner'
              />
              <Link to='/app/dashboard'>
              <Button
                fullWidth
                className='w-130'
                variant="primary"
              >
            Go to dashboard
          </Button>
          </Link>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalForm;
