import React, {useState} from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { useGlobalContext } from '../../Context/GlobalContext';
import  './HaveRetailStoreLocationStyles.css';

const HaveRetailStoreLocationComponentComponent = ({storeType, setStoreType, storeTypeError, storeTypeHelperText,  bothStoreTypeError, bothStoreTypeHelperText })=> {

  const { haveRetailStoreLocation, setHaveRetailStoreLocation, setBothStoreType, bothStoreType } = useGlobalContext();

  if(haveRetailStoreLocation){
    setStoreType(haveRetailStoreLocation)
  }

  const handleBothQuestionChange = (event) => {
     
    setBothStoreType(event.target.value);
    //const { name, value, checked } = e.target;
    // if (name === 'answers') {
    //   setBothStoreType(
    //     checked? [...bothStoreType, value]: bothStoreType.filter((answer) => answer !== value)
    //   );
    // }
    //if (name === 'answers') {
      // setBothStoreType((bothStoreType) => {
      //   if (checked) {
      //     if (!bothStoreType.includes(value)) {
      //       return [...bothStoreType, value];
      //     } else {
      //       return bothStoreType;
      //     }
      //   } else {
      //     return bothStoreType.filter((answer) => answer !== value);
      //   }
      // });
    //}
  }

  return (
      <div className='formContainerSecondStep'>
        <p className='headingFirstStep'>Type of Retailer?</p>
        <form noValidate className="ml-4"> 
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Gender</FormLabel> */}
            <RadioGroup aria-label="haveRetailStoreLocation" className='gap-[20px]' name="haveRetailStoreLocation" value={haveRetailStoreLocation} row>
              <FormControlLabel
                value="Physical Stores"
                control={
                  <div className={` ${haveRetailStoreLocation === 'Physical Stores' ? 'customRadioSelected' : 'customRadio'}`} onClick={() => setHaveRetailStoreLocation('Physical Stores')}>
                    <div  className="flex items-center mt-4">
                      <img
                        src={haveRetailStoreLocation === 'Physical Stores' ? process.env.REACT_APP_URL+'Radio-select.svg' : process.env.REACT_APP_URL+'Radio-unselect.svg'}
                        alt="Female"
                        className="circleImg"
                      />
                      <p className="typeOfStore">Physical Stores</p>
                    </div>
                  </div>
                }
                error={storeTypeError}
                helperText={storeTypeHelperText}
              />
              <FormControlLabel
                value="Online Stores"
                control={
                  <div className={` ${haveRetailStoreLocation === 'Online Stores' ? 'customRadioSelected' : 'customRadio'}`}   onClick={() => setHaveRetailStoreLocation('Online Stores')}>
                    <div  className="flex items-center mt-4">
                      <img
                        src={haveRetailStoreLocation === 'Online Stores' ? process.env.REACT_APP_URL+'Radio-select.svg' : process.env.REACT_APP_URL+'Radio-unselect.svg'}
                        alt="Female"
                        className="circleImg"
                      />
                      <p className="typeOfStore">Online Stores</p>
                    </div>
                  </div>
                }
              />
              <FormControlLabel
                value="Both"
                control={
                  <div className={` ${haveRetailStoreLocation === 'Both' ? 'customRadioSelected' : 'customRadio'}`}   onClick={() => setHaveRetailStoreLocation('Both')}>
                    <div  className="flex items-center mt-4">
                      <img
                        src={haveRetailStoreLocation === 'Both' ? process.env.REACT_APP_URL+'Radio-select.svg' : process.env.REACT_APP_URL+'Radio-unselect.svg'}
                        alt="Female"
                        className="circleImg"
                      />
                      <p className="typeOfStore">Both</p>
                    </div>
                  </div>
                }
              />
              {
                storeTypeError === true ? <p className='text-[#d32f2f] text-[12px] not-italic font-normal leading-normal font-dm-sans'>{storeTypeHelperText}</p>:null
              }
            </RadioGroup>
          </FormControl>
          {haveRetailStoreLocation === 'Both' && (
            <div className='mt-6'>
            <p className='bothQuestion'>What is your primary objective with PushSend Ai?</p>
            {/* <FormControl component="fieldset">
              <FormControlLabel
                control={<Checkbox value="Driving more foot traffic and sales to our physical locations" name="answers" onChange={handleBothQuestionChange}/>}
                label="Driving more foot traffic and sales to our physical locations"
                checked={bothStoreType.includes("Driving more foot traffic and sales to our physical locations")}
              />
              <FormControlLabel
                control={<Checkbox value="Driving more traffic and sales to our online store" name="answers" onChange={handleBothQuestionChange}/>}
                label="Driving more traffic and sales to our online store"
                checked={bothStoreType.includes("Driving more traffic and sales to our online store")}
              />
              <FormControlLabel
                control={<Checkbox value="Driving more sales to both" name="answers" onChange={handleBothQuestionChange}/>}
                label="Driving more sales to both"
                checked={bothStoreType.includes("Driving more sales to both")}
              />
              {
                bothStoreTypeError === true ? <p className='text-[#d32f2f] text-[12px] not-italic font-normal leading-normal font-dm-sans'>{bothStoreTypeHelperText}</p>:null
              }
            </FormControl> */}
            <FormControl component="fieldset">
              <RadioGroup value={bothStoreType} onChange={handleBothQuestionChange}>
                <FormControlLabel
                  control={<Radio  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18, // Control the size of the Radio button
                      color:'#646464'
                    },
                    '&.Mui-checked .MuiSvgIcon-root': {
                      color: 'rgb(150 98 255)', // Change color of the selected Radio button
                    },
                  }}/>}
                  label={<Typography variant="body2" sx={{marginBottom: '15px', fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43', color:'#646464'}}>Driving more foot traffic and sales to our physical locations</Typography>}
                  value="Driving more foot traffic and sales to our physical locations"
                  
                />
                <FormControlLabel
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18, // Control the size of the Radio button
                      color:'#646464'
                    },
                    '&.Mui-checked .MuiSvgIcon-root': {
                      color: 'rgb(150 98 255)', // Change color of the selected Radio button
                    },
                  }}/>}
                  label={<Typography variant="body2" sx={{marginBottom: '15px', fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43', color:'#646464'}}>Driving more traffic and sales to our online store</Typography>}
                  value="Driving more traffic and sales to our online store"
                />
                <FormControlLabel
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18, // Control the size of the Radio button
                      color:'#646464'
                    },
                    '&.Mui-checked .MuiSvgIcon-root': {
                      color: 'rgb(150 98 255)', // Change color of the selected Radio button
                    },
                  }}/>}
                  label={<Typography variant="body2" sx={{marginBottom: '15px', fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43', color:'#646464'}}>Driving more sales to both</Typography>}
                  value="Driving more sales to both"
                />
              </RadioGroup>
              {bothStoreTypeError && (
                <FormHelperText className="text-[#d32f2f] text-[12px] not-italic font-normal leading-normal font-dm-sans">
                  {bothStoreTypeHelperText}
                </FormHelperText>
              )}
            </FormControl>
            </div>
          )}
        </form>
      </div>
      
  )
}

 export default HaveRetailStoreLocationComponentComponent
