import React from 'react';
import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import config from '../../Config'
import  './ForgotPasswordPageStyles.css';
import ForgotPasswordContainer from '../../Containers/ForgotPasswordContainer/ForgotPasswordContainer'

function ForgotPasswordPage() {
  return (
    <Grid container>
      <Grid item xs={5} className='forgotImgBackground'>
          <div className="flex items-center justify-center min-h-screen flex-col">
            <div className="w-358">
            <img src={config.baseURL+"login-banner.gif"}
              alt='loginlogo'
              className="w-200"
            />
            </div>
            <div className="mt-2.5 px-24">
              <p className="text-white text-center text-3xl font-medium leading-normal">The Customer Conversion <br></br>and Data Insights Platform</p>
            </div>
          </div>
          
      </Grid>
      <Grid item xs={7}>
        <div className="forgotContainer">
          <div className='forgotLogoDiv'> 
            <img src={config.baseURL+"pushsend-logo.png" } 
            className='loginLogo'
            alt='loginlogo'
            />
          </div>
          <CssBaseline />
          <div className="forgotFormWrapper" elevation={3}>
              <p className="forgotHeading" >
               Forgot password?
              </p>
              <p className="text-custom-gray text-14px leading-normal mt-3">
              No worries, we’ll send you reset instructions.
              </p>
              <ForgotPasswordContainer/>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ForgotPasswordPage;

