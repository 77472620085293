import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Onboarding from '../../Components/OnboardingComponent/OnboardingComponent';
import TimelineStepper from '../../Components/TimelineStepper/StepperComponent';
import HaveRetailStoreLocation from '../../Components/HaveRetailStoreLocationComponent/HaveRetailStoreLocationComponent';
import StoreLocation from '../../Components/StoreLocationComponent/StoreLocationComponent';
import StoreDetails from '../../Components/StoreDetailsComponent/StoreDetailsComponent';
import ContentPromoDetails from '../../Components/ContentPromoDetailsComponent/ContentPromoDetailsComponent';
import ChooseMode from '../../Components/ChooseModeComponent/ChooseModeComponent';
import OnboardingFinish from '../../Components/OnboardingFinishComponent/OnboardingFinishComponent';
import BusinessModalComponent from '../../Components/BusinessModalComponent/BusinessModalComponent';
import { useGlobalContext } from '../../Context/GlobalContext';
import {postApi}from '../../apiClient';


import  './OnboardingPageStyles.css';

const libraries = ['places'];

const OnboardingPage = () => {

  const [currentStep, setCurrentStep] = useState(0);

  const [shopUrl, setShopUrl] = useState('');
  const [shopUrlError, setShopUrlError] = useState(false);
  const [shopUrlHelperText, setShopUrlHelperText] = useState('');

  const [storeKey, setStoreKey] = useState('');
  const [storeKeyError, setStoreKeyError] = useState(false);
  const [storeKeyHelperText, setStoreKeyHelperText] = useState('');

  const [activateStoreType, setActivateStoreType] = useState('');
  const [activateStoreTypeError, setActivateStoreTypeError] = useState(false);
  const [activateStoreTypeHelperText, setActivateStoreTypeHelperText] = useState('');

  const [apiSecret, setApiSecret] = useState('');
  const [apiSecretError, setApiSecretError] = useState(false);
  const [apiSecretHelperText, setApiSecretHelperText] = useState('');

  const [accessToken, setAccessToken] = useState('');
  const [accessTokenError, setAccessTokenError] = useState(false);
  const [accessTokenHelperText, setAccessTokenHelperText] = useState('');

  const [shopUserName, setShopUserName] = useState('');
  const [shopUserNameError, setShopUserNameError] = useState(false);
  const [shopUserNameHelperText, setShopUserNameHelperText] = useState('');

  const [shopPassword, setShopPassword] = useState('');
  const [shopPasswordError, setShopPasswordError] = useState(false);
  const [shopPasswordHelperText, setShopPasswordHelperText] = useState('');

  const [storeName, setStoreName] = useState('');
  const [storeNameError, setStoreNameError] = useState(false);
  const [storeNameHelperText, setStoreNameHelperText] = useState('');
  
  const [storeType, setStoreType] = useState('');
  const [storeTypeError, setStoreTypeError] = useState(false);
  const [storeTypeHelperText, setStoreTypeHelperText] = useState('');

  // const [bothStoreType, setBothStoreType] = useState([]);
  const [bothStoreTypeError, setBothStoreTypeError] = useState(false);
  const [bothStoreTypeHelperText, setBothStoreTypeHelperText] = useState('');
  
  const [storeLocation, setStoreLocation] = useState([]);
  const [storeLocationError, setStoreLocationError] = useState(false);
  const [storeLocationHelperText, setStoreLocationHelperText] = useState('');

  const [category, setCategory] = useState('');
  const [categoryError, setCategoryError] = useState(false);
  const [categoryHelperText, setCategoryHelperText] = useState('');

  const [subcategory, setSubcategory] = useState([]);
  const [subcategoryError, setSubcategoryError] = useState(false);
  const [subcategoryHelperText, setSubcategoryHelperText] = useState('');

  const [keyAudience, setKeyAudience] = useState('');
  const [keyAudienceError, setKeyAudienceError] = useState(false);
  const [keyAudienceHelperText, setKeyAudienceHelperText] = useState('');

  const [overallContentTone, setOverallContentTone] = useState([]);
  const [overallContentToneError, setOverallContentToneError] = useState(false);
  const [overallContentToneHelperText, setOverallContentToneHelperText] = useState('');

  const [keyTarget, setKeyTarget] = useState('');
  const [keyTargetError, setKeyTargetError] = useState(false);
  const [keyTargetHelperText, setKeyTargetHelperText] = useState('');

  const [storeDescription, setStoreDescription] = useState('');
  const [storeDescriptionError, setStoreDescriptionError] = useState(false);
  const [storeDescriptionHelperText, setStoreDescriptionHelperText] = useState('');

  const [digitalPromotionPerYear, setDigitalPromotionPerYear] = useState('');
  const [digitalPromotionPerYearError, setDigitalPromotionPerYearError] = useState(false);
  const [digitalPromotionPerYearHelperText, setDigitalPromotionPerYearHelperText] = useState('');

  const [offerDiscount, setOfferDiscount] = useState('');
  const [offerDiscountError, setOfferDiscountError] = useState(false);
  const [offerDiscountHelperText, setOfferDiscountHelperText] = useState('');

  const [sliderValue, setSliderValue] = useState(30);

  const [instractionToTrain, setInstractionToTrain] = useState('');

  const [keyIncludes, setKeyIncludes] = useState([]);
  const [keyIncludesError, setKeyIncludesError] = useState(false);
  const [keyIncludesHelperText, setKeyIncludesHelperText] = useState('');

  const [keyIncludesSecond, setKeyIncludesSecond] = useState([]);
  const [keyIncludesSecondError, setKeyIncludesSecondError] = useState(false);
  const [keyIncludesSecondHelperText, setKeyIncludesSecondHelperText] = useState('');

  const [maximumConversion, setMaximumConversion] = useState('');
  const [maximumConversionError, setMaximumConversionError] = useState(false);
  const [maximumConversionHelperText, setMaximumConversionHelperText] = useState('');

  const [majorHolidays, setMajorHolidays] = useState('');
  const [majorHolidaysError, setMajorHolidaysError] = useState(false);
  const [majorHolidaysHelperText, setMajorHolidaysHelperText] = useState('');

  const [generalEvents, setGeneralEvents] = useState('');
  const [generalEventsError, setGeneralEventsError] = useState(false);
  const [generalEventsHelperText, setGeneralEventsHelperText] = useState('');

  const [abandonedCart, setAbandonedCart] = useState('');
  const [abandonedCartError, setAbandonedCartError] = useState(false);
  const [abandonedCartHelperText, setAbandonedCartHelperText] = useState('');

  const [firstTimeBuyer, setFirstTimeBuyer] = useState('');
  const [firstTimeBuyerError, setFirstTimeBuyerError] = useState(false);
  const [firstTimeBuyerHelperText, setFirstTimeBuyerHelperText] = useState('');

  const [loyalCustomer, setLoyalCustomer] = useState('');
  const [loyalCustomerError, setLoyalCustomerError] = useState(false);
  const [loyalCustomerHelperText, setLoyalCustomerHelperText] = useState('');

  const [maxOffBirthday, setMaxOffBirthday] = useState('');
  const [maxOffBirthdayError, setMaxOffBirthdayError] = useState(false);
  const [maxOffBirthdayHelperText, setMaxOffBirthdayHelperText] = useState('');

  const [generalPromotion, setGeneralPromotion] = useState('');

  const [maxOffFollowups, setMaxOffFollowups] = useState('');

  const [viewedTwoTimes, setViewedTwoTimes] = useState('');

  const [chooseMode, setChooseMode] = useState('');
  const [chooseModeError, setChooseModeError] = useState(false);
  const [chooseModeHelperText, setChooseModeHelperText] = useState('');

  const { setLoading, showToast, haveRetailStoreLocation, setHaveRetailStoreLocation, bothStoreType, setBothStoreType } = useGlobalContext();

  const [businessModalOpen, setBusinessModalOpen] = useState(false);

  const handleBusinessModalOpen = () => {
    setBusinessModalOpen(true);
  };

  const handleBusinessModalClose = () => {
    setBusinessModalOpen(false);
  };

  const handleSubmitStepOne = async (e) => {
  
    try{
        e.preventDefault();
        setLoading(true);
        setHaveRetailStoreLocation('');
        setBothStoreType('');
        let valid = true;

        // if (!shopUserName) {
        //   setShopUserNameError(true);
        //   setShopUserNameHelperText('Please enter your Shopify user name');
        //   valid = false;
        //   setLoading(false);
        // } else {
        //   setShopUserNameError(false);
        //   setShopUserNameHelperText('');
        // }

        // if (!shopPassword) {
        //   setShopPasswordError(true);
        //   setShopPasswordHelperText('Please enter your Shopify user password');
        //   valid = false;
        //   setLoading(false);
        // } else {
        //   setShopPasswordError(false);
        //   setShopPasswordHelperText('');
        // }
        if (!shopUrl) {
          setShopUrlError(true);
          setShopUrlHelperText('Please enter the shop url');
          valid = false;
          setLoading(false);
        } else {
          setShopUrlError(false);
          setShopUrlHelperText('');
        }
      
        // if (!storeKey) {
        //   setStoreKeyError(true);
        //   setStoreKeyHelperText('Please enter the store key');
        //   valid = false;
        //   setLoading(false);
        // } else {
        //   setStoreKeyError(false);
        //   setStoreKeyHelperText('');
        // }
      
        // if (!activateStoreType) {
        //   setActivateStoreTypeError(true);
        //   setActivateStoreTypeHelperText('Please enter the store type');
        //   valid = false;
        //   setLoading(false);
        // } else {
        //   setActivateStoreTypeError(false);
        //   setActivateStoreTypeHelperText('');
        // }
      
        // if (!apiSecret) {
        //   setApiSecretError(true);
        //   setApiSecretHelperText('Please enter the api secret');
        //   valid = false;
        //   setLoading(false);
        // } else {
        //   setApiSecretError(false);
        //   setApiSecretHelperText('');
        // }
      
        if (!accessToken) {
          setAccessTokenError(true);
          setAccessTokenHelperText('Please enter the access token');
          valid = false;
          setLoading(false);
        } else {
          setAccessTokenError(false);
          setAccessTokenHelperText('');
        }

        if (!storeName) {
          setStoreNameError(true);
          setStoreNameHelperText('Please enter the store name');
          valid = false;
          setLoading(false);
        } else {
          setStoreNameError(false);
          setStoreNameHelperText('');
        }

        if (valid) {
          let stepOneFormData = {
            storeName:storeName,
            storeType:'Test',
            email:localStorage.getItem("ownerEmail"),
            storeAttribute:[{}],
            auth:{
              shopUrl : shopUrl,
              // storeKey:storeKey,
              // apiSecret:apiSecret,
              accessToken:accessToken
            }
          }

          let response = await postApi('api/store',stepOneFormData)
          const { message, storeId} = response.data;
          if (response.status === 200 && message) {
            localStorage.setItem('storeId', storeId);
            showToast('Activation successful. We are now syncing all data with PushSend Ai', 'success');
            setLoading(false);
            handleNext()
          }
        }
    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }
  };

  const handleSubmitStepTwo = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);
      let valid = true;
      
      if (!storeType) {
        setStoreTypeError(true);
        setStoreTypeHelperText('Please choose the store type');
        valid = false;
        setLoading(false);
      } else {
        setStoreTypeError(false);
        setStoreTypeHelperText('');
      }

      if (bothStoreType === '' && storeType === "Both") {
        setBothStoreTypeError(true);
        setBothStoreTypeHelperText('Please choose your primary objective with PushSend Ai');
        valid = false;
        setLoading(false);
      } else {
        setBothStoreTypeError(false);
        setBothStoreTypeHelperText('');
      }
     
      if(valid){
        if(storeType === 'Online Stores'){
          let stepTwoFormData = {
            storeId:localStorage.getItem("storeId"),
            locations:[],
            isStoreRetail:storeType,
            idealOutcomeWithPushsend: ''
          }

          let response = await postApi('api/update-store-details',stepTwoFormData)
          const { message} = response.data;
          if (response.status === 200 && message) {
            //showToast(message, 'success');
            setLoading(false);
            setBothStoreType('')
            handleNext()
          }
        }else{
          setLoading(false);
          handleNext()
        }
      }

    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }

  }

  const handleSubmitStepThree = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);
      let valid = true;
      if (storeLocation.length < 1) {
        setStoreLocationError(true);
        setStoreLocationHelperText('Please choose the store location');
        valid = false;
        setLoading(false);
      } else {
        setStoreLocationError(false);
        setStoreLocationHelperText('');
      }

      if(haveRetailStoreLocation !== 'Both'){
        setBothStoreType('');
      }

      if(valid){
        let stepThreeFormData = {
          storeId:localStorage.getItem("storeId"),
          locations:storeLocation,
          isStoreRetail:haveRetailStoreLocation,
          idealOutcomeWithPushsend: haveRetailStoreLocation !== 'Both'?'':bothStoreType
        }

        let response = await postApi('api/update-store-details',stepThreeFormData)
        const { message} = response.data;
        if (response.status === 200 && message) {
          //showToast(message, 'success');
          setLoading(false);
          handleNext()
        }
      }

    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }

  }

  const handleSubmitStepFour = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);
      let valid = true;
      if (!category) {
        setCategoryError(true);
        setCategoryHelperText('Please choose the category');
        valid = false;
        setLoading(false);
      } else {
        setCategoryError(false);
        setCategoryHelperText('');
      }

      if (!subcategory) {
        setSubcategoryError(true);
        setSubcategoryHelperText('Please choose the sub category');
        valid = false;
        setLoading(false);
      } else {
        setSubcategoryError(false);
        setSubcategoryHelperText('');
      }

      if (!keyAudience) {
        setKeyAudienceError(true);
        setKeyAudienceHelperText('Please choose the key audience');
        valid = false;
        setLoading(false);
      } else {
        setKeyAudienceError(false);
        setKeyAudienceHelperText('');
      }

      if (!overallContentTone) {
        setOverallContentToneError(true);
        setOverallContentToneHelperText('Please choose the overall content tone');
        valid = false;
        setLoading(false);
      } else {
        setOverallContentToneError(false);
        setOverallContentToneHelperText('');
      }

      if (!keyTarget) {
        setKeyTargetError(true);
        setKeyTargetHelperText('Please choose the key target');
        valid = false;
        setLoading(false);
      } else {
        setKeyTargetError(false);
        setKeyTargetHelperText('');
      }

      if (!storeDescription) {
        setStoreDescriptionError(true);
        setStoreDescriptionHelperText('Please choose the store description');
        valid = false;
        setLoading(false);
      } else {
        setStoreDescriptionError(false);
        setStoreDescriptionHelperText('');
      }

      if (!digitalPromotionPerYear) {
        setDigitalPromotionPerYearError(true);
        setDigitalPromotionPerYearHelperText('Please enter digital promotion do you want per year');
        valid = false;
        setLoading(false);
      } else {
        setDigitalPromotionPerYearError(false);
        setDigitalPromotionPerYearHelperText('');
      }

      if (!offerDiscount) {
        setOfferDiscountError(true);
        setOfferDiscountHelperText('Please enter offer discount');
        valid = false;
        setLoading(false);
      } else {
        setOfferDiscountError(false);
        setOfferDiscountHelperText('');
      }

      if(valid){
        let stepFourFormData = {
          storeId:localStorage.getItem("storeId"),
          description:storeDescription,
          attributes : {
            category : [category],
            subCategory:subcategory,
            keyAudience:[keyAudience],
            contentTone:overallContentTone,
            keyTargets:[keyTarget],
            // discountPromos: sliderValue,
            digitalPromotionPerYear: digitalPromotionPerYear,
            offerDiscount: offerDiscount,
            instructionToTrain: instractionToTrain
          }
        }

        let response = await postApi('api/update-store-attribute',stepFourFormData)
        const { message} = response.data;
        if (response.status === 200 && message) {
          //showToast(message, 'success');
          setLoading(false);
          handleNext()
        }
      }

    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }

  }

  const handleSubmitStepFive = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);
      let valid = true;
      if (keyIncludes.length<1) {
        setKeyIncludesError(true);
        setKeyIncludesHelperText('Please enter keywords');
        valid = false;
        setLoading(false);
      } else {
        setKeyIncludesError(false);
        setKeyIncludesHelperText('');
      }

      if (keyIncludesSecond.length<1) {
        setKeyIncludesSecondError(true);
        setKeyIncludesSecondHelperText('Please enter keywords');
        valid = false;
        setLoading(false);
      } else {
        setKeyIncludesSecondError(false);
        setKeyIncludesSecondHelperText('');
      }

      if (!maximumConversion) {
        setMaximumConversionError(true);
        setMaximumConversionHelperText('Please enter maximum conversion per month');
        valid = false;
        setLoading(false);
      } else {
        setMaximumConversionError(false);
        setMaximumConversionHelperText('');
      }

      // if (!majorHolidays) {
      //   setMajorHolidaysError(true);
      //   setMajorHolidaysHelperText('Please enter major holiday percentage');
      //   valid = false;
      //   setLoading(false);
      // } else {
      //   setMajorHolidaysError(false);
      //   setMajorHolidaysHelperText('');
      // }

      // if (!generalEvents) {
      //   setGeneralEventsError(true);
      //   setGeneralEventsHelperText('Please enter general events percentage');
      //   valid = false;
      //   setLoading(false);
      // } else {
      //   setGeneralEventsError(false);
      //   setGeneralEventsHelperText('');
      // }

      // if (!abandonedCart) {
      //   setAbandonedCartError(true);
      //   setAbandonedCartHelperText('Please enter abandoned cart percentage');
      //   valid = false;
      //   setLoading(false);
      // } else {
      //   setAbandonedCartError(false);
      //   setAbandonedCartHelperText('');
      // }

      // if (!firstTimeBuyer) {
      //   setFirstTimeBuyerError(true);
      //   setFirstTimeBuyerHelperText('Please enter first time buyer percentage');
      //   valid = false;
      //   setLoading(false);
      // } else {
      //   setFirstTimeBuyerError(false);
      //   setFirstTimeBuyerHelperText('');
      // }

      // if (!loyalCustomer) {
      //   setLoyalCustomerError(true);
      //   setLoyalCustomerHelperText('Please enter loyal customer percentage');
      //   valid = false;
      //   setLoading(false);
      // } else {
      //   setLoyalCustomerError(false);
      //   setLoyalCustomerHelperText('');
      // }

      // if (!maxOffBirthday) {
      //   setMaxOffBirthdayError(true);
      //   setMaxOffBirthdayHelperText('Please enter maximum off birthday percentage');
      //   valid = false;
      //   setLoading(false);
      // } else {
      //   setMaxOffBirthdayError(false);
      //   setMaxOffBirthdayHelperText('');
      // }

      if(valid){
        let stepFiveFormData = {
          storeId:localStorage.getItem("storeId"),
          contentDetails : {
            keyIncludes : keyIncludes,
            keyIncludesSecond : keyIncludesSecond,
            maximumConversion : maximumConversion
          },
          promoDetails : {
            majorHolidaysPercentage : majorHolidays,
            generalEventsPercentage : generalEvents,
            abandonedCartPercentage : abandonedCart,
            firstTimeBuyerPercentage : firstTimeBuyer,
            loyalCustomerPercentage : loyalCustomer,
            maxOffBirthdayPercentage : maxOffBirthday,
            generalPromotionPercentage : generalPromotion,
            maxOffFollowupsPercentage : maxOffFollowups,
            viewedTwoTimesPercentage : viewedTwoTimes
          }
        }

        let response = await postApi('api/update-store-content',stepFiveFormData)
        const { message} = response.data;
        if (response.status === 200 && message) {
          //showToast(message, 'success');
          setLoading(false);
          handleNext()
        }
      }

    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }

  }

  const handleSubmitStepSix = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);
      let valid = true;
      if (!chooseMode) {
        setChooseModeError(true);
        setChooseModeHelperText('Please choose the mode');
        valid = false;
        setLoading(false);
      } else {
        setChooseModeError(false);
        setChooseModeHelperText('');
      }

      if(valid){
        let stepSixFormData = {
          storeId:localStorage.getItem("storeId"),
          mode:chooseMode
        }

        let response = await postApi('api/add-store-mode',stepSixFormData)
        const { message} = response.data;
        if (response.status === 200 && message) {
          //showToast(message, 'success');
          setHaveRetailStoreLocation('');
          setLoading(false);
          handleNext()
        }
      }

    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }

  }

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      if(storeType === "Online Stores" && currentStep===1){
        setCurrentStep(currentStep + 2);
      }else{
        setCurrentStep(currentStep + 1);
      }
      
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      if(storeType === "Online Stores" && currentStep===3){
        setCurrentStep(currentStep - 2);
      }else{
        setCurrentStep(currentStep - 1);
      }
    }
  };

  
  let steps = [
    {
        id: 1,
        name : "Store Activation",
        isShow : false,
        component : <Onboarding 
                      shopUserName={shopUserName}
                      setShopUserName={setShopUserName}
                      shopUserNameError={shopUserNameError}
                      shopUserNameHelperText={shopUserNameHelperText}
                      shopPassword={shopPassword}
                      setShopPassword={setShopPassword}
                      shopPasswordError={shopPasswordError}
                      shopPasswordHelperText={shopPasswordHelperText}
                      shopUrl={shopUrl}
                      setShopUrl={setShopUrl}
                      storeName={storeName}
                      setStoreName={setStoreName}
                      storeKey={storeKey}
                      setStoreKey={setStoreKey}
                      shopUrlError={shopUrlError}
                      storeNameError={storeNameError}
                      storeKeyError={storeKeyError}
                      shopUrlHelperText={shopUrlHelperText}
                      storeNameHelperText={storeNameHelperText}
                      storeKeyHelperText={storeKeyHelperText}
                      handleSubmitStepOne={handleSubmitStepOne}
                      handleNext={handleNext}
                      activateStoreType={activateStoreType}
                      setActivateStoreType={setActivateStoreType}
                      activateStoreTypeError={activateStoreTypeError}
                      activateStoreTypeHelperText={activateStoreTypeHelperText}
                      apiSecret={apiSecret}
                      setApiSecret={setApiSecret}
                      apiSecretError={apiSecretError}
                      apiSecretHelperText={apiSecretHelperText}
                      accessToken={accessToken}
                      setAccessToken={setAccessToken}
                      accessTokenError={accessTokenError}
                      accessTokenHelperText={accessTokenHelperText}
                      // storeName={storeName}
                      // setStoreName={setStoreName}
                      // storeNameError={storeNameError}
                      // storeNameHelperText={storeNameHelperText}
                      // handleSubmitStepOne={handleSubmitStepOne}
                      // handleNext={handleNext}
                    />
    },
    {
      id: 2,
      name : "Store Type",
      isShow : false,
      component : <HaveRetailStoreLocation
                    storeType={storeType}
                    setStoreType={setStoreType}
                    storeTypeError={storeTypeError}
                    storeTypeHelperText={storeTypeHelperText}
                    // bothStoreType={bothStoreType}
                    // setBothStoreType={setBothStoreType}
                    bothStoreTypeError={bothStoreTypeError}
                    bothStoreTypeHelperText={bothStoreTypeHelperText}
                  />
    },
    {
      id: 3,
      name : "Store Location",
      isShow : false,
      component : <StoreLocation
                    storeLocation={storeLocation}
                    setStoreLocation={setStoreLocation}
                    storeLocationError={storeLocationError}
                    storeLocationHelperText={storeLocationHelperText}
                    libraries={libraries}
                  />
    },
    {
      id: 4,
      name : "Store Details",
      isShow : false,
      component : <StoreDetails
                    category={category}
                    setCategory={setCategory}
                    categoryError={categoryError}
                    categoryHelperText={categoryHelperText}
                    subcategory={subcategory}
                    subcategoryError={subcategoryError}
                    setSubcategory={setSubcategory}
                    subcategoryHelperText={subcategoryHelperText}
                    keyAudience={keyAudience}
                    setKeyAudience={setKeyAudience}
                    keyAudienceError={keyAudienceError}
                    keyAudienceHelperText={keyAudienceHelperText}
                    overallContentTone={overallContentTone}
                    setOverallContentTone={setOverallContentTone}
                    overallContentToneError={overallContentToneError}
                    overallContentToneHelperText={overallContentToneHelperText}
                    keyTarget={keyTarget}
                    setKeyTarget={setKeyTarget}
                    keyTargetError={keyTargetError}
                    keyTargetHelperText={keyTargetHelperText}
                    storeDescription={storeDescription}
                    setStoreDescription={setStoreDescription}
                    storeDescriptionError={storeDescriptionError}
                    storeDescriptionHelperText={storeDescriptionHelperText}
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                    digitalPromotionPerYear={digitalPromotionPerYear}
                    setDigitalPromotionPerYear={setDigitalPromotionPerYear}
                    digitalPromotionPerYearError={digitalPromotionPerYearError}
                    digitalPromotionPerYearHelperText={digitalPromotionPerYearHelperText}
                    offerDiscount={offerDiscount}
                    setOfferDiscount={setOfferDiscount}
                    offerDiscountError={offerDiscountError}
                    offerDiscountHelperText={offerDiscountHelperText}
                    setInstractionToTrain={setInstractionToTrain}
                    instractionToTrain={instractionToTrain}
                  />
    },
    {
      id: 5,
      name : "Content & Promo Details",
      isShow : false,
      component : <ContentPromoDetails
                    keyIncludes={keyIncludes}
                    setKeyIncludes={setKeyIncludes}
                    keyIncludesError={keyIncludesError} 
                    keyIncludesHelperText={keyIncludesHelperText} 
                    keyIncludesSecond={keyIncludesSecond}
                    setKeyIncludesSecond={setKeyIncludesSecond}
                    keyIncludesSecondError={keyIncludesSecondError} 
                    keyIncludesSecondHelperText={keyIncludesSecondHelperText} 
                    maximumConversion={maximumConversion}
                    setMaximumConversion={setMaximumConversion}
                    maximumConversionError={maximumConversionError} 
                    maximumConversionHelperText={maximumConversionHelperText} 
                    majorHolidays={majorHolidays}
                    setMajorHolidays={setMajorHolidays}
                    majorHolidaysError={majorHolidaysError} 
                    majorHolidaysHelperText={majorHolidaysHelperText} 
                    generalEvents={generalEvents}
                    setGeneralEvents={setGeneralEvents}
                    generalEventsError={generalEventsError} 
                    generalEventsHelperText={generalEventsHelperText} 
                    abandonedCart={abandonedCart}
                    setAbandonedCart={setAbandonedCart}
                    abandonedCartError={abandonedCartError} 
                    abandonedCartHelperText={abandonedCartHelperText} 
                    firstTimeBuyer={firstTimeBuyer}
                    setFirstTimeBuyer={setFirstTimeBuyer}
                    firstTimeBuyerError={firstTimeBuyerError} 
                    firstTimeBuyerHelperText={firstTimeBuyerHelperText}
                    loyalCustomer={loyalCustomer}
                    setLoyalCustomer={setLoyalCustomer}
                    loyalCustomerError={loyalCustomerError} 
                    loyalCustomerHelperText={loyalCustomerHelperText} 
                    maxOffBirthday={maxOffBirthday}
                    setMaxOffBirthday={setMaxOffBirthday}
                    maxOffBirthdayError={maxOffBirthdayError} 
                    maxOffBirthdayHelperText={maxOffBirthdayHelperText}
                    generalPromotion={generalPromotion}
                    setGeneralPromotion={setGeneralPromotion}
                    maxOffFollowups={maxOffFollowups}
                    setMaxOffFollowups={setMaxOffFollowups}
                    viewedTwoTimes={viewedTwoTimes}
                    setViewedTwoTimes={setViewedTwoTimes}
                  />
    },
    {
      id: 6,
      name : "Choose Mode",
      isShow : false,
      component : <ChooseMode
                    chooseMode={chooseMode}  
                    setChooseMode={setChooseMode}
                    chooseModeError={chooseModeError}
                    chooseModeHelperText={chooseModeHelperText}           
                  />
    },
    {
      id: 7,
      name : "Finish",
      isShow : false,
      component : <OnboardingFinish/>
    },
  ]; 

  
  return (
    <div className='dashContainer'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <div className='firstStepLeftSide'>
    
            {steps[currentStep].component}
            
            { (steps[currentStep].id !== 1 && steps[currentStep].id !== 7)?
            <div className='buttonPlacement'> 
                <Button
                  type="submit"
                  variant="outlined"
                  onClick={handlePrevious}
                >
                  <img
                    src={process.env.REACT_APP_URL+"back-long-arrow-.svg"}
                    alt="back"
                  />
                  Back
                </Button>
                <Button
                  type="submit"
                  // disabled="true"
                  // variant={(storeType && steps[currentStep].id===2)?"primary":"secondary"}
                  //onClick={handleNext}
                  variant="primary"
                  onClick={
                    steps[currentStep].id===1?handleSubmitStepOne
                    :steps[currentStep].id===2?handleSubmitStepTwo
                    :steps[currentStep].id===3?handleSubmitStepThree
                    :steps[currentStep].id===4?handleSubmitStepFour
                    :steps[currentStep].id===5?handleSubmitStepFive
                    :steps[currentStep].id===6?handleSubmitStepSix
                    :handleNext}
                >
                  Next
                  <img
                    src={process.env.REACT_APP_URL+"next-long-arrow-right.svg"}
                    alt="next"
                  />
                </Button>
              </div>
              :null}
            { steps[currentStep].id===1?
            <>
              <p className='text-gray-600 text-[13px] font-normal leading-normal pl-14 mb-3'>Don't have a shopify store? <Link onClick={handleBusinessModalOpen}>Please let us know about your business</Link></p>
              <p className='text-gray-600 text-[12px] font-normal leading-normal pl-14 mb-8'>Don't worry we only store what we absolutely need in accordance with our <br/><Link>Privacy Policy</Link> and <Link>Security Policy</Link></p>
            </>
            :null}
          </div>
          <div>
              <BusinessModalComponent 
                  setBusinessModalOpen={setBusinessModalOpen}
                  businessModalOpen={businessModalOpen}
                  handleBusinessModalOpen={handleBusinessModalOpen}
                  handleBusinessModalClose={handleBusinessModalClose}
              />
          </div>
        </Grid>
        <Grid item xs={12} md={5} className='firstStepRightSide'>
          <TimelineStepper steps={steps} currentStep={currentStep} storeType={storeType} />
        </Grid>
      </Grid>
    </div>
  )
}

export default OnboardingPage
