import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from '../../Components/NavbarComponent/NavComponent';
import Sidebar from '../../Components/SidebarComponent/SidebarComponent';
import { Outlet } from "react-router-dom";
import {  Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import './AppLayoutPage.css';

const AppLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
      // <div>
      //   <Grid container>
      //     <Grid item xs={2} >
      //       <Sidebar  />
      //     </Grid>
      //     <Grid item xs={10}>
      //       <Navbar  />
      //       <Box >
      //       <Outlet />
      //       </Box>
      //     </Grid>
      //   </Grid>
      // </div>
      <div className="responsiveLayoutContainer">
        <Grid container>
          {/* {!isMobile && ( */}
            <Grid item xs={2}>
              <Sidebar />
            </Grid>
          {/* )} */}
          <Grid item xs={isMobile ? 12 : 10}>
            <Navbar />
            <Box className="contentContainer">
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      </div>
  );
}

export default AppLayout;
