import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import  './OnboardingStyles.css';
import { WidthFull } from '@mui/icons-material';

// {shopUserName, setShopUserName, shopUserNameError, shopUserNameHelperText, storeName, setStoreName, storeNameError, storeNameHelperText,
//   shopPassword, setShopPassword, shopPasswordError, shopPasswordHelperText, handleSubmitStepOne, handleNext}
const OnboardingComponent = ({shopUserName, setShopUserName, shopUserNameError, shopUserNameHelperText,
shopPassword, setShopPassword, shopPasswordError, shopPasswordHelperText, shopUrl, setShopUrl, storeName, setStoreName, storeKey, setStoreKey, shopUrlError, storeNameError,
storeKeyError, shopUrlHelperText, storeNameHelperText, storeKeyHelperText, handleSubmitStepOne, handleNext, activateStoreType, 
setActivateStoreType, activateStoreTypeError, activateStoreTypeHelperText, apiSecret, setApiSecret, apiSecretError, apiSecretHelperText,
accessToken, setAccessToken, accessTokenError, accessTokenHelperText})=> {
  return (
      
      <div className='formContainerFirstStep'>
        <p className='headingFirstStep'>Lets get your shopify store activated</p>
        <form noValidate className='formWidth'>
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="shopUserName"
            label="Shopify User Name"
            placeholder='Please enter your Shopify user name'
            name="shopUserName"
            autoComplete="shopUserName"
            autoFocus
            value={shopUserName}
            onChange={(e) => setShopUserName(e.target.value)}
            error={shopUserNameError}
            helperText={shopUserNameHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title=" Shopify user name">
                    <IconButton>
                      <img src={process.env.REACT_APP_URL+"info-circle 1.svg"} alt='info'/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          /> */}
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="shopPassword"
            label="Shopify Password"
            placeholder='Please enter your shopify password'
            name="shopPassword"
            autoComplete="shopPassword"
            autoFocus
            value={shopPassword}
            onChange={(e) => setShopPassword(e.target.value)}
            error={shopPasswordError}
            helperText={shopPasswordHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Shopify Password">
                    <IconButton>
                      <img src={process.env.REACT_APP_URL+"info-circle 1.svg"} alt='info'/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          /> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="shopUrl"
            label="Shop URL"
            placeholder='Enter shop URL'
            name="shopUrl"
            autoComplete="shopUrl"
            autoFocus
            value={shopUrl}
            onChange={(e) => setShopUrl(e.target.value)}
            error={shopUrlError}
            helperText={shopUrlHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Shop URL">
                    <IconButton>
                      <img src={process.env.REACT_APP_URL+"info-circle 1.svg"} alt='info'/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="storeName"
            label="Store Name"
            placeholder='Enter store name'
            type="text"
            id="storeName"
            autoComplete="storeName"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
            error={storeNameError}
            helperText={storeNameHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Store Name">
                    <IconButton>
                      <img src={process.env.REACT_APP_URL+"info-circle 1.svg"} alt='info'/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="activateStoreType"
            label="Store Type"
            placeholder='Enter store type'
            type="text"
            id="activateStoreType"
            autoComplete="activateStoreType"
            value={activateStoreType}
            onChange={(e) => setActivateStoreType(e.target.value)}
            error={activateStoreTypeError}
            helperText={activateStoreTypeHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Store Type">
                    <IconButton>
                      <img src={process.env.REACT_APP_URL+"info-circle 1.svg"} alt='info'/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          /> */}
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="storeKey"
            label="Store Key"
            placeholder='Enter store key'
            type="text"
            id="storeKey"
            autoComplete="storeKey"
            value={storeKey}
            onChange={(e) => setStoreKey(e.target.value)}
            error={storeKeyError}
            helperText={storeKeyHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Store Key">
                    <IconButton>
                      <img src={process.env.REACT_APP_URL+"info-circle 1.svg"} alt='info'/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          /> */}
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="apiSecret"  
            label="Api Secret"
            placeholder='Enter api secret'
            type="text"
            id="apiSecret"
            autoComplete="apiSecret"
            value={apiSecret}
            onChange={(e) => setApiSecret(e.target.value)}
            error={apiSecretError}
            helperText={apiSecretHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Api Secret">
                    <IconButton>
                      <img src={process.env.REACT_APP_URL+"info-circle 1.svg"} alt='info'/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          /> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="accessToken"
            label="Shopify Store Key"
            placeholder='Enter shopify store key'
            type="text"
            id="accessToken"
            autoComplete="accessToken"
            value={accessToken}
            onChange={(e) => setAccessToken(e.target.value)}
            error={accessTokenError}
            helperText={accessTokenHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Access Token">
                    <IconButton>
                      <img src={process.env.REACT_APP_URL+"info-circle 1.svg"} alt='info'/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {/* <div
            fullWidth
            className='shopifyButton'
            //onClick={(e)=>{handleSubmitStepOne(e)}}
            onClick={()=>{handleNext()}}
          >
            <img src={process.env.REACT_APP_URL+'shopify-color 1.png'} />
            Connect with shopify
          </div> */}
          <Button
            // type="submit"
            fullWidth
            className='w-130'
            variant="primary"
            onClick={(e)=>{handleSubmitStepOne(e)}}
            //onClick={()=>{handleNext()}}
          >
            Activate
          </Button>
        </form>
      </div>
      
  )
}

 export default OnboardingComponent
