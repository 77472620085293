import React, {useState, useEffect} from 'react';
import Divider from '@mui/material/Divider';
import EditStoreTypeComponent from '../../Components/EditStoreTypeComponent/EditStoreTypeComponent';
import EditStoreLocationComponent from '../../Components/EditStoreLocationComponent/EditStoreLocationComponent';
import EditStoreDetailsComponent from '../../Components/EditStoreDetailsComponent/EditStoreDetailsComponent';
import EditContentPromoDetailsComponent from '../../Components/EditContentPromoDetailsComponent/EditContentPromoDetailsComponent';
import EditChooseModeComponent from '../../Components/EditChooseModeComponent/EditChooseModeComponent';
import { useGlobalContext } from '../../Context/GlobalContext';
import { getStoreDetailsApi } from '../../API/createPromotion/createPromotion';

import  './StoreSettingPageStyles.css';

function StoreSettingPage() {

  const [editStoreType, setEditStoreType] = useState('');
  const [editStoreTypeError, setEditStoreTypeError] = useState(false);
  const [editStoreTypeHelperText, setEditStoreTypeHelperText] = useState('');

  const [editBothStoreType, setEditBothStoreType] = useState('');
  const [editBothStoreTypeError, setEditBothStoreTypeError] = useState(false);
  const [editBothStoreTypeHelperText, setEditBothStoreTypeHelperText] = useState('');
  const [onboardingStoreDetailsData, setOnboardingStoreDetailsData] = useState([]);
  const { setLoading, showToast } = useGlobalContext();
  const userEmail = localStorage.getItem('ownerEmail');

  useEffect(()=>{
    getOnboadingStoreDetailsForEditApi();
  },[]);

  const getOnboadingStoreDetailsForEditApi = async () => {
    setLoading(true);
    let response = await getStoreDetailsApi(userEmail);
    const { stores } = response.data;
    if (response.status === 200 && stores.length>0) {
      setOnboardingStoreDetailsData(stores[0]);
      setLoading(false);
    }
    setLoading(false);
  }

  return (
    <div className='storeSettingContainer'>
      <div>
        <div className='storeSettingHeading'>
          <p className='storeStepHeading'>Store Type</p>
          <Divider 
          variant='middle'
          className='storeSettingHorizontal'
          />
        </div>
        <div>
          <EditStoreTypeComponent
            editStoreType={editStoreType}
            setEditStoreType={setEditStoreType}
            editStoreTypeError={editStoreTypeError}
            editStoreTypeHelperText={editStoreTypeHelperText}
            editBothStoreType={editBothStoreType}
            setEditBothStoreType={setEditBothStoreType}
            editBothStoreTypeError={editBothStoreTypeError}
            editBothStoreTypeHelperText={editBothStoreTypeHelperText}
            setEditStoreTypeError={setEditStoreTypeError}
            setEditStoreTypeHelperText={setEditStoreTypeHelperText}
            setEditBothStoreTypeError={setEditBothStoreTypeError}
            setEditBothStoreTypeHelperText={setEditBothStoreTypeHelperText}
            onboardingStoreDetailsData={onboardingStoreDetailsData}
          />
        </div>
      </div>

      <div className='mt-5'>
        <div className='storeSettingHeading'>
          <p className='storeStepHeading'>Store Location</p>
          <Divider 
          variant='middle'
          className='storeSettingHorizontal'
          />
        </div>
        <div>
          <EditStoreLocationComponent
            onboardingStoreDetailsData={onboardingStoreDetailsData}
            editStoreType={editStoreType}
          />
        </div>
      </div>

      <div className='mt-5'>
        <div className='storeSettingHeading'>
          <p className='storeStepHeading'>Store Details</p>
          <Divider 
          variant='middle'
          className='storeSettingHorizontal'
          />
        </div>
        <div>
          <EditStoreDetailsComponent
            onboardingStoreDetailsData={onboardingStoreDetailsData}
          />
        </div>
      </div>

      <div className='mt-5'>
        <div className='storeSettingHeading'>
          <p className='storeStepHeading'>Content & Promo Details</p>
          <Divider 
          variant='middle'
          className='storeSettingHorizontal'
          />
        </div>
        <div>
          <EditContentPromoDetailsComponent
            onboardingStoreDetailsData={onboardingStoreDetailsData}
          />
        </div>
      </div>

      <div className='mt-5'>
        <div className='storeSettingHeading'>
          <p className='storeStepHeading'>Mode Selection</p>
          <Divider 
          variant='middle'
          className='storeSettingHorizontal'
          />
        </div>
        <div>
          <EditChooseModeComponent
            onboardingStoreDetailsData={onboardingStoreDetailsData}
          />
        </div>
      </div>

      
    </div>
  );
}

export default StoreSettingPage;
