import React, { useState } from 'react';
import "./CustomerListSidebar.css";

const CustomerListSidebar = ({ onCustomerSelect }) => {
    // const [buttonClicked, setButtonClicked] = useState(false);

    const handleClicked = () => {
        const token = true;
        // setButtonClicked(true);
        onCustomerSelect(token);

    }
    return (
        <>
            <div className='customerListContainer'>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Tom Johnson.svg'} className='customerImage' />
                    <button onClick={handleClicked} className='customerName'>Jessica Walter</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Tom Johnson.svg'} className='customerImage' />
                    <button className='customerName'>Tom Johnson</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Bruce Miller.svg'} className='customerImage' />
                    <button className='customerName'>Bruce Miller</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Timothy walker.svg'} className='customerImage' />
                    <button className='customerName'>Timothy walker</button>
                </div >
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Janney Smuck.svg'} className='customerImage' />
                    <button className='customerName'>Janney Smuck</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Pam Walter.svg'} className='customerImage' />
                    <button className='customerName'>Pam Walter</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Jannet Wong.svg'} className='customerImage' />
                    <button className='customerName'>Jannet Wong</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Logan Paul.svg'} className='customerImage' />
                    <button className='customerName'>Logan Paul</button>
                </div>
            </div>
        </>
    )
}

export default CustomerListSidebar;
