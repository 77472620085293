import React, { useState, useEffect } from 'react';
import  './SubscriptionPlanStyles.css';
import { Tabs, Tab, Typography, Box, Grid, Button, Modal } from '@mui/material';
import {postApi, getPaymentApi}from '../../apiClient';
import SubscriptionCheckoutComponent from './SubscriptionCheckoutComponent';

const SubscriptionPlanComponent = () => {
  const [value, setValue] = useState(0);
  const [planList, setPlanList] = useState([]);
  const [choosenPlanId, setChoosenPlanId] = useState('');
  const [checkoutLinkOpen, setCheckoutLinkOpen] = useState(false);
  const [subscriptionPayModalOpen, setSubscriptionPayModalOpen] = useState(false);

  const handleSubscriptionPayModalOpen = () => {
    setSubscriptionPayModalOpen(true);
  };

  const handleSubscriptionPayModalClose = () => {
    setSubscriptionPayModalOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  useEffect(() => {
    handlePlanChooseNext()
  }, []); 

  const handlePlanChooseNext = async () => {
    let response = await getPaymentApi('api/plan')
     const { plans } = response.data;
    if (plans.length>0) {
      setPlanList(plans)
    }
  }

  const filteredPlans = planList?.filter(item => item.interval === 'month')
  .map((plan, index) => {
    return {
      ...plan,
      newValue: plan.value * 2, 
      index: index
    };
  });

  const filteredYearlyPlans = planList?.filter(item => item.interval === 'year')
  .map((plan, index) => {
    return {
      ...plan,
      newValue: plan.value * 2, 
      index: index
    };
  });

  const subscriptionCheckout = async (planId) => {
    setChoosenPlanId(planId);
    setCheckoutLinkOpen(true);
    handleSubscriptionPayModalOpen();
    
  }

  return (
  <>
    <div className="planTab">
      <Tabs
        value={value}
        onChange={handleChange}
        className="customTabs"
      >
        <Tab label="Monthly" className="customTab" />
        <Tab label="Annually" className="customTab" />
      </Tabs>
    </div>
    <TabPanel value={value} index={0}>
      <div className='planTabContainer'>
        <Grid container className="root">
          {filteredPlans.map((plan, index) => 
            <Grid item xs={12} sm={6} md={4} lg={3} className='planTabColumn' >
                <div className='planTabDetails'>
                  <p className='font-inter font-[600] leading-normal tracking-wide text-[16px] mt-8'>{plan.product.name}</p>
                  <p><span className='text-[#1D2127] font-inter text-[36px] font-[800] leading-normal tracking-wider'>${plan.amount/100}</span><span className='text-[#646464] font-inter text-[12px] font-[400] leading-normal tracking-wider'>per {plan.interval}</span></p>
                  <p className='text-[#646464] font-inter text-[14px] font-[500] leading-normal tracking-wider'>Marketing up to 1200<br/> customers</p>
                  <div className='mt-4'> 
                    {plan?.product?.marketing_features?.map((marketing, idx) =>
                      <div className='planItemDes' key={idx}>
                        <img src={process.env.REACT_APP_URL+'Tick.svg'} className='w-6 h-5'/>
                        <p className='text-[#171717] font-inter text-[14px] font-[300] leading-normal tracking-wider pt-0'>{marketing?.name}</p>
                      </div>
                    )}
                  </div>
                  <div className='planButton'>
                    <Button
                      fullWidth
                      variant="subscription"
                      //onClick={subscriptionCheckout({plan.id})}
                      onClick={() => subscriptionCheckout(plan.id)}
                    >
                      Choose Plan
                    </Button>
                  </div>
                </div>
            </Grid>
          )}
        </Grid>
      </div>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <div className='planTabContainer'>
        <Grid container className="root">
        {filteredYearlyPlans.map((yearlyPlan, index) => 
          <Grid item xs={12} sm={6} md={4} lg={3} className='planTabColumn' >
              <div className='planTabDetails'>
                <p className='font-inter font-[600] leading-normal tracking-wide text-[16px] mt-8'>{yearlyPlan.product.name}</p>
                <p><span className='text-[#1D2127] font-inter text-[36px] font-[800] leading-normal tracking-wider'>${yearlyPlan.amount/100}</span><span className='text-[#646464] font-inter text-[12px] font-[400] leading-normal tracking-wider'>per {yearlyPlan.interval}</span></p>
                <p className='text-[#646464] font-inter text-[14px] font-[500] leading-normal tracking-wider'>Marketing up to 1200<br/> customers</p>
                <div className='mt-4'> 
                  {yearlyPlan?.product?.marketing_features?.map((marketingDetails, idx) =>
                    <div className='planItemDes' key={idx}>
                      <img src={process.env.REACT_APP_URL+'Tick.svg'} className='w-6 h-5'/>
                      <p className='text-[#171717] font-inter text-[14px] font-[300] leading-normal tracking-wider pt-0'>{marketingDetails?.name}</p>
                    </div>
                  )}
                </div>
                <div className='planButton'>
                  <Button
                    fullWidth
                    variant="subscription"
                    //onClick={subscriptionCheckout({plan.id})}
                    onClick={() => subscriptionCheckout(yearlyPlan.id)}
                  >
                    Choose Plan
                  </Button>
                </div>
              </div>
          </Grid>
         )}
        </Grid>
      </div>
    </TabPanel>

    {
      checkoutLinkOpen === true &&
      <Modal open={subscriptionPayModalOpen} onClose={handleSubscriptionPayModalClose} className="modal">
          <div className="modalPaper">
              <div className='subsHeadingCrossPosition'>
                <p className='subsModalHeading'> Create your subscription </p>
                <div className='subsCrossOnModal' onClick={handleSubscriptionPayModalClose}>
                  <img src={process.env.REACT_APP_URL+'Cross-1171276068.svg'} alt='cross'/>
                </div>
              </div>
              {console.log("choosenPlanId",choosenPlanId)}
              <SubscriptionCheckoutComponent
                  planId = {choosenPlanId}
                  setSubscriptionPayModalOpen = {setSubscriptionPayModalOpen}
              />
          </div>
      </Modal>
    }
  </>
    
  );
};

export default SubscriptionPlanComponent;
