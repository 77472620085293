import React from 'react';

const Toast = ({ message, type }) => {
    const toastType = type === 'error' ? 'bg-red-500' : 'bg-[#bba1f0]';
    return (
      <div className="fixed top-5 inset-0 flex justify-center z-50">
        <div className={`w-164 h-12 p-4 flex justify-center items-center rounded shadow-lg ${toastType} text-white`}>
          {message}
        </div>
      </div>
    );
  };
  
  export default Toast;
  