import { createTheme } from '@mui/material/styles';

let theme = createTheme({
    components: {
        MuiButton: {
          styleOverrides: {
            primary: {
              marginTop: '15px',
              borderRadius: '10px',
              background: 'var(--Linear, linear-gradient(90deg, #684FFF 0%, #9C65FF 100%))',
              boxShadow: '5px 5px 12px 0px rgba(82, 54, 255, 0.05)',
              display: 'flex',
              height: '38px',
              padding: '7px 14px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '3.5px',
              alignSelf: 'stretch',
              fontFamily: 'DM Sans, sans-serif',
              color: '#FFF',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              textTransform: 'uppercase' 
            },
            outlined: {
              fontSize: '12px',
              color: '#9F9F9F',
              marginTop: '15px',
              borderRadius: '10px',
              border: '1px solid #BCBCBC',
              background: '#FDFEFF',
              width: '95px',
              display: 'flex',
              height: '38px',
              padding: '7px 14px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '3.5px',
              alignSelf: 'stretch',
              fontFamily: 'DM Sans, sans-serif'
            },
            text: {
              color: '#FFF',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              textTransform: 'uppercase' 
            },
            secondary: {
              marginTop: '15px',
              borderRadius: '10px',
              opacity: '0.5',
              background: 'var(--Linear, linear-gradient(90deg, #684FFF 0%, #9C65FF 100%))',
              boxShadow: '5px 5px 12px 0px rgba(82, 54, 255, 0.05)',
              display: 'flex',
              height: '38px',
              padding: '7px 14px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '3.5px',
              alignSelf: 'stretch',
              fontFamily: 'DM Sans, sans-serif',
              color: '#FFF',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              textTransform: 'uppercase'  
            },
            subscription: {
              marginTop: '15px',
              borderRadius: '8px',
              opacity: '0.5',
              background: 'var(---Tertiary-2, #F1EFFF)',
              display: 'flex',
              padding: '7.5px 62px 8.5px 62px', 
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              fontFamily: 'Inter',
              color: 'var(--684-fff, #684FFF)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
              textTransform: 'none' 
            },
            promotion: {
              marginTop: '15px',
              borderRadius: '10px',
              border: '1px solid #4318FF',
              display: 'flex',
              padding: '5px 25px 5px 25px', 
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              fontFamily: 'DM Sans',
              color: '#4318FF',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '20px',
              textTransform: 'none' 
            }
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#CCC', // Default border color
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#5236ff', // Border color on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#5236ff', // Border color when focused
              },
              // height: '40px', // Custom height for the TextField
              '& .MuiOutlinedInput-input': {
                // padding: '10px 16px', // Adjust padding to fit the custom height
              },
              fontSize: '12px',
              // '&::placeholder': {
              //   fontSize: '12px', // Adjust the font size of the placeholder text
              // },
              notchedOutline: {
                top: 0, // Adjust the top positioning to align the label
              },
            },
          },
        },
        MuiFormHelperText:{
          styleOverrides: {
            root: {
              color:'#d32f2f',
              lineHeight: '10px',
              marginTop: '5px',
              marginLeft: '2px',
              fontFamily: 'DM Sans, sans-serif',

            }
          }
        },
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //       '& .MuiOutlinedInput-root': {
        //         '&:hover .MuiOutlinedInput-notchedOutline': {
        //           borderColor: '#5236ff', // Custom hover border color
        //         },
        //       },
        //     },
        //   },
        // },
        MuiInputLabel: {
            styleOverrides: {
              root: {
                color: '#646464', // Custom label color
                fontSize: '12px', // Custom label font size
                fontFamily: 'DM Sans, sans-serif',
                top: '-9px',
                '&.MuiFormLabel-root-MuiInputLabel-root':{
                  marginTop:'10px'
                },
              },
              shrink: {
                top: 0, // Adjust the top positioning when the label is shrunk
              },
            },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              color: '#646464', // Custom label color
              fontSize: '12px', // Custom label font size
              fontFamily: 'DM Sans, sans-serif',
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              maxHeight: '200px', // Set the maximum height for the dropdown menu
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                backgroundColor: '#C8ADFF !important', // background color for selected option
                '&:hover': {
                  //backgroundColor: '#C8ADFF !important', // background color for hovered selected option
                },
              },
              '&:hover': {
                //backgroundColor: '#C8ADFF !important', // background color for hovered option
              },
              fontFamily: 'DM Sans, sans-serif',
              fontSize:'12px'
            },
            
          },
        },
        MuiListItemText: {
          styleOverrides: {
            primary: {
              fontFamily: 'DM Sans, sans-serif',
              fontSize:'12px'
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: '16px', // Font size
              color: '#555', // Font color
              textTransform: 'none', // Disable text transformation
              minWidth: '125px', // Minimum width
              '&.Mui-selected': {
                width: '100%', // Full width for selected tab
                color: '#fff', // Selected tab text color
                backgroundColor: '#000000 !important', // Background color for selected tab
                border: '1px solid transparent', // Transparent border
                borderRadius: '50px', // Rounded corners for selected tab
              },
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              backgroundColor: 'transparent', // Remove underline
            },
            flexContainer: {
              alignItems: 'stretch', // Align items to stretch
              gap: '10px', // Gap between tabs
              // marginTop: '50px', // Top margin
              borderRadius: '50px', // Rounded corners
              background: '#F2F2F2', // Background color for the tab container
              height: '50px', // Set height
              justifyContent: 'space-between', // Space between tabs
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              paddingTop: '4px',
              paddingBottom: '5px'
              // Add other styles here
            },
          },
        },
        // MuiLink: {
        //     styleOverrides: {
        //       root: {
        //         color: '#5236ff', // Custom link color
        //         '&:hover': {
        //           color: '#5236ff', // Custom hover color
        //         },
        //       },
        //     },
        // },
    },
    palette: {
    primary: {
        main: '#3311db', //#422afb  #5236ff
    },
    },
});

theme = createTheme(theme, {
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('sm')]: {
            fontSize: '14px', // Smaller font size for mobile
            minWidth: '100px', // Smaller minimum width for mobile
          },
          [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '15px', // Font size for tablets
            minWidth: '115px', // Minimum width for tablets
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          [theme.breakpoints.down('sm')]: {
            height: '48px', // Reduced height for mobile
            gap: '5px', // Reduced gap between tabs for mobile
          },
          [theme.breakpoints.between('sm', 'md')]: {
            marginTop: '40px', // Top margin for tablets
            height: '48px', // Height for tablets
          },
        },
      },
    },
  },
});

export default theme;
