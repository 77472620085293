
import React, { createContext, useContext, useState } from 'react';

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [haveRetailStoreLocation, setHaveRetailStoreLocation] = useState('');
  const [bothStoreType, setBothStoreType] = useState('');

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000); // Hide toast after 3 seconds
  };

  return (
    <GlobalContext.Provider value={{ 
        loading, setLoading, 
        toast, showToast, 
        haveRetailStoreLocation, setHaveRetailStoreLocation,
        bothStoreType, setBothStoreType
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
