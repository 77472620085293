import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import  './PromotionCalendarStyles.css';
import { useGlobalContext } from '../../Context/GlobalContext';
import { getPromotionListApi } from '../../API/createPromotion/createPromotion';
//import PromotionDetailsModal from '../../Components/PromotionDetailsModalComponent/PromotionDetailsModalComponent';

function PromotionCalendar({handlePromotionEventClick}) {
  const user_id=localStorage.getItem('userId');
 // const [isPromotionClicked, setIsPromotionClicked] = useState(false);
  //const [isPromotionDetailModalVisible, setIsPromotionDetailModalVisible] = useState(false);
  const [promotionListArray, setPromotionListArray] = useState([]);
  const { setLoading, showToast } = useGlobalContext();
  //const [promotionId, setPromotionId] = useState();

  // Handle date clicks
  // const handleDateClick = (arg) => {
  //   alert(`Date clicked: ${arg.dateStr}`);
  // };
  // Handle event drops
  // const handleEventDrop = (info) => {
  //   alert(`Event dropped to ${info.event.start.toISOString()}`);
  // };

  useEffect(() => {
    getListofPromotionApi();
  }, [])

  const getListofPromotionApi = async () => {
    setLoading(true);
    let response = await getPromotionListApi(user_id);
    const { promotions, success } = response.data;
    if (response.status === 200 && promotions.length > 0 && success === true) {
      setPromotionListArray(promotions);
      setLoading(false);
    }
  }

  // Render custom content for events
  const eventContent = (eventInfo) => {
    return (
      <div>
        <b>{eventInfo.event.title}</b>
      </div>
    );
  };

  const promotionDataArray = promotionListArray.map((element) => ({
    title: element.promotionName,
    start: element.startDate,
    end: element.endDate,
    id: element.promotionId,
    extendedProps: { isImportant: true }
  }));

  const handlePromotionClick = (info) => {
    const eventpromotionId = info.event.id;
    handlePromotionEventClick(eventpromotionId);
  }

  // useEffect(() => {
  //   if (isPromotionClicked) {
  //     setIsPromotionDetailModalVisible(true);
  //   }
  // }, [isPromotionClicked])

  // const handlePromotionDetailsModalCancel = () => {
  //   setIsPromotionDetailModalVisible(false);
  //   setIsPromotionClicked(false);
  // };

  // const handleOk = () => {
  //   setIsPromotionDetailModalVisible(false);
  //   setIsPromotionClicked(false);
  // }
  return (
    <>
      <div className='calendarContainer'>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "title",
            center: "",
            right: "prev,today,next",
          }}
          views={{
            listWeek: {
              type: 'listWeek',
              duration: { days: 7 }
            }
          }}
          eventSources={[
            {
              events: [
                ...promotionDataArray
              ]
            }]}
          eventClick={(e)=>handlePromotionClick(e)}
          eventContent={eventContent}
          editable={true}
          selectable={true}
          // eventDrop={handleEventDrop}
          // dateClick={handleDateClick}
        />
      </div>
      {/* <div>
        <PromotionDetailsModal
          promotionId={promotionId}
          isVisible={isPromotionDetailModalVisible}
          onClose={handlePromotionDetailsModalCancel}
        />
      </div> */}
    </>
  );
}

export default PromotionCalendar;
