import React, { useState } from 'react';
import { Grid, InputLabel, MenuItem, FormControl, Select, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomerListSidebar from '../../Components/CustomerListSideBarComponent/CustomerListSidebar';
import "./InboxPage.css";

const InboxPage = () => {

    const [promotionDate, setPromotionDate] = useState(null);
    const [selectedPromotion, setSelectedPromotion] = useState('');
    const [selectedTab, setSelectedTab] = useState('SMS');
    const [token, setToken] = useState(null);

    const handleSelectedPromotion = (event) => {
        setSelectedPromotion(event.target.value);
    };

    const handlePromotionDateChange = (date) => {
        setPromotionDate(date);
    };
    const handleCustomerSelect = (token) => {
        setToken(token);
    };

    const handleTabButtonClick = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <div className='inboxPageContainer'>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={3} mx={1}>
                    <CustomerListSidebar onCustomerSelect={handleCustomerSelect} />
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    <div className='selectorSection'>
                        <div>
                            <FormControl fullWidth >
                                <InputLabel>Select Promotion</InputLabel>
                                <Select
                                    value={selectedPromotion}
                                    onChange={handleSelectedPromotion}
                                    label="Select Promotion"
                                    className='promotionSelect'
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    className="datePicker"
                                    label="Month/Year"
                                    views={['month', 'year']}
                                    value={promotionDate}
                                    onChange={handlePromotionDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    {token ? (
                        <div className='messageTabsContainer'>
                            <div className="tabsWrapper">
                                <div className="tabButtons">
                                    <div
                                        onClick={() => handleTabButtonClick('SMS')}
                                        className={selectedTab === "SMS" ? 'tabButtonActive' : 'tabButtonInactive'}
                                    >
                                        SMS
                                    </div>
                                    <div
                                        onClick={() => handleTabButtonClick('Email')}
                                        className={selectedTab === "Email" ? 'tabButtonActive' : 'tabButtonInactive'}
                                    >
                                        Email
                                    </div>
                                    <div
                                        onClick={() => handleTabButtonClick('Whatsapp')}
                                        className={selectedTab === "Whatsapp" ? 'tabButtonActive' : 'tabButtonInactive'}
                                    >
                                        Whatsapp
                                    </div>
                                </div>
                                <div className="messageBoxContainer">
                                    <div className="messageContentWrapper">
                                        <div className="messageHeaderSection">
                                            {selectedTab === "SMS" ?
                                                <img src={process.env.REACT_APP_URL + 'smsIcon.png'} />
                                                : selectedTab === "Email" ?
                                                    <img src={process.env.REACT_APP_URL + 'smsIcon.png'} />
                                                    :
                                                    <img src={process.env.REACT_APP_URL + 'whatsappIcon.jpg'} className='w-[24px] h-[25px]' />
                                            }
                                            <div className="messageHeaderTitle">{selectedTab}</div>
                                        </div>
                                        <hr className="horizonDivider" />
                                        {selectedTab === 'SMS' &&
                                            <div className="messageDetails">
                                                SMS This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                            </div>
                                        }
                                        {selectedTab === 'Email' &&
                                            <div className="messageDetails">
                                                Email This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                            </div>
                                        }
                                        {selectedTab === 'Whatsapp' &&
                                            <div className="messageDetails">
                                                Whatsapp This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                                This Box renders as an HTML section element. This Box renders as an HTML section element.
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='messageContainer'>
                            <div className='messageWrapper'>
                                <img src={process.env.REACT_APP_URL + 'Inbox.png'} alt='InboxIcon' className='inboxImage' />
                                <p className='instructionalMessage'>Please select the time range and promotion to see the conversation</p>
                            </div>
                        </div>
                    )
                    }

                </Grid>
            </Grid>
        </div>
    )
}

export default InboxPage;