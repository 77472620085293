import React from 'react'
import LoginComponent from '../../Components/LoginComponent/LoginComponent'

const LoginContainer = () => {
  return (
    <><LoginComponent/></>
  )
}

export default LoginContainer
