import React, { useEffect } from 'react';
import {postApi, getDetailsById}from '../../apiClient';
import { useGlobalContext } from '../../Context/GlobalContext';
import { useNavigate, useLocation } from 'react-router-dom';

function ActiveEmailPage() {
  const location = useLocation();
  const { setLoading, showToast } = useGlobalContext();
  const navigate = useNavigate();
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    // Define an async function to fetch data
    const fetchData = async () => {
      setLoading(true);
      try {
        let response = await getDetailsById('api/users',id)
        const { email } = response.data[0];
        if (email) {
          let formData = {
            email : email
          }
          let finalResponse = await postApi('api/verify-user',formData)
          const { message } = finalResponse.data;
          if(message){
            showToast(message, 'success');
            setLoading(false);
            navigate('/subscription-plan');
          }
        }
      } catch (error) {
        setLoading(false);
        showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
        console.log("error",error)
      }
    };
    // Call the async function
    fetchData();
  }, []);
  return (
    <></>
  );
}

export default ActiveEmailPage;
