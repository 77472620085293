import React from 'react';
import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import config from '../../Config'
import  './VerifyEmailPageStyles.css';
import VerifyEmailContainer from '../../Containers/VerifyEmailContainer/VerifyEmailContainer'

function VerifyEmailPage() {
  return (
    <Grid container>
      <Grid item xs={5} className='verifyImgBackground'>
          <div className="flex items-center justify-center min-h-screen flex-col">
            <div className="w-358">
            <img src={config.baseURL+"login-banner.gif"}
              alt='loginlogo'
              className="w-200"
            />
            </div>
            <div className="mt-2.5 px-24">
              <p className="text-white text-center text-3xl font-medium leading-normal">The Customer Conversion <br></br>and Data Insights Platform</p>
            </div>
          </div>
          
      </Grid>
      <Grid item xs={7}>
        <div className="verifyContainer">
          <div className='verifyLogoDiv'> 
            <img src={config.baseURL+"pushsend-logo.png" }
            alt='loginlogo'
            className='loginLogo'
            />
          </div>
          <CssBaseline />
          <div className="verifyFormWrapper" elevation={3}>
              <p className="verifyHeading" >
              Check your email
              </p>
              <p className="text-custom-gray text-14px leading-normal mt-3">
              To start using PushSend Ai, confirm your email address with the email sent.
              </p>
              <VerifyEmailContainer/>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default VerifyEmailPage;
