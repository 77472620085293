import React, { useState } from 'react';
import { Container, Grid, Box, Typography, Button } from '@mui/material';
// import Avatar from '@mui/material/Avatar';
// import avatarImage from '../Image/walpaper.jpg';
import './ViewConversationStyles.css';

const ViewConversationComponent = ({ promotion, setIsViewConversationVisible, setIsViewPromotionList }) => {
  const [selectedTab, setSelectedTab] = useState('sms');

  const handleButtonClick = (tab) => {
    setSelectedTab(tab);
  };
  const viewConeversationBack = () => {
    setIsViewConversationVisible(false);
    setIsViewPromotionList(true);
  }
  return (

    <div className='conversationContainer'>
      <div className='viewConversationBackButton'>
        <img src={process.env.REACT_APP_URL + 'back-long-arrow-.svg'} alt='backIcon' onClick={viewConeversationBack} className='cursor-pointer' />
        <p className='promotionNameTitle'>{promotion.promotionName}</p>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2} mt={2} mx={1} className='gridContainer'>
            <Grid item display="flex" justifyContent="center" alignItems="center" className='girdBox'>
              <div className='userImageBackground'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button className='gridText'>Jessica Walter</button>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center" className='girdBox' >
              <div className='userImageBackground'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button className='gridText'>Tom Johnson</button>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center" className='girdBox' >
              <div className='userImageBackground'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button className='gridText'>Bruce Miller</button>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center" className='girdBox' >
              <div className='userImageBackground'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button className='gridText'>Timothy Walker</button>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center" className='girdBox' >
              <div className='userImageBackground'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button className='gridText'>Janney Smuck</button>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center" className='girdBox' >
              <div className='userImageBackground'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button className='gridText'>Pam Walter</button>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center" className='girdBox' >
              <div className='userImageBackground'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button className='gridText'>Jannet Wong</button>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center" className='girdBox' >
              <div className='userImageBackground'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button className='gridText'>Logan Paul</button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9} mt={4} >
          <Grid mx={1}>
            <Grid container spacing={1} className='ml-5'>
              {promotion?.deliveryPlatform?.map((platform, index) =>
                <Grid item>
                  <Button
                    onClick={() => handleButtonClick(platform)}
                    className={selectedTab === platform ? 'tabButtonSelected' : 'tabButton'}
                  >
                    <p className={selectedTab === platform ? 'tabButtonTextSelected' : 'tabButtonText'}>{platform}</p>
                    <hr className='text-[#000]' />
                  </Button>
                </Grid>
              )}
              {/* <Grid item>
                <Button
                  onClick={() => handleButtonClick('Email')}
                  className='tabButton'
                >
                  <p className='tabButtonText'>Email</p>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleButtonClick('Whatsapp')}
                  className='tabButton'
                >
                  <p className='tabButtonText'>Whatsapp</p>
                </Button>
              </Grid> */}
            </Grid>
            <Grid xs={10} className='gridMessageBoxContainer'>
              <Box component="section" className='contentBoxBorder'>
                <div>
                  <div className='generatedContentHearder'>
                    {
                      selectedTab === "sms" ?
                        <img src={process.env.REACT_APP_URL + 'message-icon.png'} className='w-[24px] h-[25px]' />
                        : selectedTab === "email" ?
                          <img src={process.env.REACT_APP_URL + 'message-icon.png'} className='w-[24px] h-[25px]' />
                          :
                          <img src={process.env.REACT_APP_URL + 'whatsappIcon.jpg'} className='w-[24px] h-[25px]' />
                    }
                    <p className='generatedContentTitle'>{selectedTab}</p>
                  </div>
                  <hr className='horizonDivider' />
                  {selectedTab === 'sms' &&
                    <div className='messageDetailsContainer'> SMS This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                    </div>
                  }
                  {selectedTab === 'email' &&
                    <div className='messageDetailsContainer'> Email This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                    </div>
                  }
                  {selectedTab === 'whatsapp' &&
                    <div className='messageDetailsContainer'> Whatsapp This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                    </div>
                  }

                </div>

              </Box>
            </Grid>
          </Grid>

        </Grid>
      </Grid >
    </div >

  )
}

export default ViewConversationComponent
