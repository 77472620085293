import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {postApi, getDetailsById}from '../../apiClient';
import { useGlobalContext } from '../../Context/GlobalContext';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import  './ResetPasswordStyles.css';

function ResetPasswordForm() {
  const [newpassword, setNewpassword] = useState('');
  const [password, setPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [newPasswordHelperText, setNewPasswordHelperText] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const { setLoading, showToast } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickShowPasswordOne = () => {
    setShowPasswordOne(!showPasswordOne);
  };

  const handleClickShowPasswordTwo = () => {
    setShowPasswordTwo(!showPasswordTwo);
  };

  const handleSubmit = async (event) => {
    try{
      event.preventDefault();
      setLoading(true);
      let valid = true;

      if (newpassword.length < 6) {
        setNewPasswordError(true);
        setNewPasswordHelperText('Password must be at least 6 characters long.');
        valid = false;
        setLoading(false);
      } else {
        setNewPasswordError(false);
        setNewPasswordHelperText('');
      }

      if (password.length < 6) {
        setPasswordError(true);
        setPasswordHelperText('Password must be at least 6 characters long.');
        valid = false;
        setLoading(false);
      } else {
        setPasswordError(false);
        setPasswordHelperText('');
      }

      if (password != newpassword) {
        setPasswordError(true);
        setPasswordHelperText('New password should be match with confirm password.');
        valid = false;
        setLoading(false);
      } else {
        setPasswordError(false);
        setPasswordHelperText('');
      }

      if (valid) {
        const params = new URLSearchParams(location.search);
        const id = params.get('id');
        if(id){
          let emailResponse = await getDetailsById('api/users',id)
          const { email } = emailResponse.data[0];
            if(email) {
              let formData = {
                email : email,
                password:password
              }
              let response = await postApi('api/reset-password',formData)
              if (response.status == 200) {
                showToast('Password has been changed. Please try login with new password', 'success');
                navigate('/login');
                setLoading(false);
              }
            }
        }else{
          setLoading(false);
          showToast('Error! : something went wrong', 'success');
        }
        // Handle form submission
        
      }
    } catch (error){
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }
  };
  return (
        <form className='resetForm' noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="newpassword"
            type={showPasswordOne ? 'text' : 'password'}
            label="New Password"
            name="newpassword"
            autoComplete="password"
            autoFocus
            value={newpassword}
            onChange={(e) => setNewpassword(e.target.value)}
            error={newPasswordError}
            helperText={newPasswordHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordOne}
                    edge="end"
                  >
                    {showPasswordOne ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confirm Password"
            type={showPasswordTwo ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError}
            helperText={passwordHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordTwo}
                    edge="end"
                  >
                    {showPasswordTwo ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <Link to="/forgot-password" className='btnForgotPassword'>Forgot Password?</Link> */}
          <div className="mt-4">
          <Button
            type="submit"
            fullWidth
            variant="primary"
          >
              Submit
          </Button>
          </div>
          <Typography align='center' pt={6}>Password changed?<Link to="/login" className='btnSignupLoginPage'> Login</Link></Typography>
        </form>
  );
}

export default ResetPasswordForm;
