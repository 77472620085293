// src/components/SignUp.js
import React  from 'react';
import { Button } from '@mui/material';
import {postApi, getDetailsById}from '../../apiClient';
import { useGlobalContext } from '../../Context/GlobalContext';
import { useNavigate, useLocation } from 'react-router-dom';

const VerifyEmail = () => {
  const location = useLocation();
  const { setLoading, showToast } = useGlobalContext();
  const navigate = useNavigate();

  const clickedOnResend = async () => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    setLoading(true);
    try {
      let response = await getDetailsById('api/users',id)
      const { email } = response.data[0];
      if (email) {
          let formData = {
            email : email
          }
          let finalResponse = await postApi('api/resendVerifyEmail',formData)
          const { message } = finalResponse.data;
          if(message){
            showToast(message, 'success');
            setLoading(false);
            // navigate('/login');
          }
      }
    } catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }
  };
  

  return (
              <div>
                <div className="mt-5">
                  <Button
                    type="submit"
                    variant="primary"
                    fullWidth
                    href="https://mail.google.com/"
                    target='_blank'
                    sx={{width:'100%'}}
                  >
                    Open email inbox
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    fullWidth
                    // href='/forgot-password'
                    onClick={clickedOnResend}
                  >
                    Resend email
                  </Button>
                </div>
              </div>
         
  );
};

export default VerifyEmail;
