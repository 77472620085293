import React from 'react';
import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import config from '../../Config'
import  './SignupPageStyles.css';
import SignupContainer from '../../Containers/SignupContainer/SignupContainer';

function SignupPage() {
  return (
    <Grid container className="root">
      <Grid item xs={12} md={5} className='signupImgBackground'>
          <div className="flex items-center justify-center min-h-screen flex-col">
            <div className="w-358">
            <img src={config.baseURL+"login-banner.gif"}
              alt='signup banner'
              className="w-200"
            />
            </div>
            <div className="mt-2.5 px-24">
              <p className="text-white text-center text-3xl font-medium leading-normal">Sync your Customer Data <br></br>and Get Started Today!</p>
            </div>
          </div>
          
      </Grid>
      <Grid item xs={12} md={7}>
        <div className="signupContainer">
          <div className='signupLogoDiv'> 
            <img src={config.baseURL+"pushsend-logo.png" }
            alt='signup logo'
            />
          </div>
          <CssBaseline />
          <div className="signupFormWrapper" elevation={3}>
              <p className="signupHeading" >
              Create your account
              </p>
              <p className="text-custom-gray text-14px leading-normal ml-5">
              Join PushSend Ai today and watch your revenue grow.
              </p>
              <SignupContainer/>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default SignupPage;

