import APIPAYMENT from "../ApiClient/ApiClientPayment";
import { SUBSCRIPTIONS_ROUTES_URL } from "../Constant/Constant";

export function updateSubscription(params = {}) {
  return APIPAYMENT.post(SUBSCRIPTIONS_ROUTES_URL.UPGRADE_PLAN, params);
}

export function getPreviousPlanById(params = {}) {
  return APIPAYMENT.get(SUBSCRIPTIONS_ROUTES_URL.GET_CURRENT_PLAN, { params });
}
