import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ScrollToTop from '../../Layout/ScrollToTop';

import  './ContentPromoDetailsStyles.css';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const ContentPromoDetailsComponent = ({
  keyIncludes,
  setKeyIncludes,
  keyIncludesError,
  keyIncludesHelperText,
  keyIncludesSecond,
  setKeyIncludesSecond,
  keyIncludesSecondError,
  keyIncludesSecondHelperText,
  maximumConversion,
  setMaximumConversion,
  maximumConversionError,
  maximumConversionHelperText,
  majorHolidays,
  setMajorHolidays,
  majorHolidaysError,
  majorHolidaysHelperText,
  generalEvents,
  setGeneralEvents,
  generalEventsError,
  generalEventsHelperText,
  abandonedCart,
  setAbandonedCart,
  abandonedCartError,
  abandonedCartHelperText,
  firstTimeBuyer,
  setFirstTimeBuyer,
  firstTimeBuyerError,
  firstTimeBuyerHelperText,
  loyalCustomer,
  setLoyalCustomer,
  loyalCustomerError,
  loyalCustomerHelperText,
  maxOffBirthday,
  setMaxOffBirthday,
  maxOffBirthdayError,
  maxOffBirthdayHelperText,
  generalPromotion,
  setGeneralPromotion,
  maxOffFollowups,
  setMaxOffFollowups,
  viewedTwoTimes,
  setViewedTwoTimes

})=> {
 
  const [value, setValue] = React.useState(0);
  const [tags, setTags] = useState([]);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTagAddition = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      const newTag = event.target.value.trim();
      if (!keyIncludes.includes(newTag)) {
        setKeyIncludes([...keyIncludes, newTag]);
        event.target.value = '';
      }
    }
  };

  const handleKeywordExclude = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      const neweTag = event.target.value.trim();
      if (!keyIncludesSecond.includes(neweTag)) {
        setKeyIncludesSecond([...keyIncludesSecond, neweTag]);
        event.target.value = '';
      }
    }
  };

  const handleTagDelete = (tagToDelete) => () => {
    setKeyIncludes((currentTags) => currentTags.filter((tag) => tag !== tagToDelete));
  };

  const handleExkeywordsDelete = (keyToDelete) => () => {
    setKeyIncludesSecond((currTags) => currTags.filter((extag) => extag !== keyToDelete));
  };

  const handleCheckboxChange = (event) => {
    setCheckboxValue(event.target.checked);
  };

  return (
    <div>
      <ScrollToTop/>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered style={{ padding: '0 36px'}} >
          <Tab label="Content details" {...a11yProps(0)} />
          <Tab label="Promo details" {...a11yProps(1)} />
        </Tabs>
      
        <CustomTabPanel value={value} index={0}>
          <div className='formContainerFifthStep'>
            <form className='contentPromoDetailsForm' noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  placeholder='Add keywords to include'
                  label=" Keywords to Include"
                  onKeyDown={handleTagAddition}
                  name='keyIncludes'
                  // value={keyIncludes}
                  error={keyIncludesError}
                  helperText={keyIncludesHelperText}
                  
                />
                {keyIncludes.map((tag, index) => (
                  
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={handleTagDelete(tag)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    deleteIcon={<CloseIcon />}
                    style={{ margin: '4px'}}
                  />
                ))}
              
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="keywordsToExclude"
                  placeholder='Add keywords to exclude'
                  label=" Keywords to Exclude"
                  type="text"
                  id="exKeywords"
                  autoComplete="exKeywords"
                  onKeyDown={handleKeywordExclude}
                  // value={keyIncludesSecond}
                  //onChange={(e) => setKeyIncludesSecond(e.target.value)}
                  error={keyIncludesSecondError}
                  helperText={keyIncludesSecondHelperText}
                  
                />

                {keyIncludesSecond.map((extag, idx) => (
                  
                  <Chip
                    key={idx}
                    label={extag}
                    onDelete={handleExkeywordsDelete(extag)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    deleteIcon={<CloseIcon />}
                    style={{ margin: '4px'}}
                  />
                ))}

                {/* <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="maxConversion"
                  label="maximum conversion sent per month (to each customer)"
                  placeholder='Enter maximum conversion sent per month '
                  type="text"
                  id="maxConversion"
                  autoComplete="maxConversion"
                  value={maximumConversion}
                  onChange={(e) => setMaximumConversion(e.target.value)}
                  error={maximumConversionError}
                  helperText={maximumConversionHelperText}
                  
                /> */}
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={8}>
                    <p variant="body1" className='promoContentText'>Maximum conversion sent per month (to each customer)</p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      type="number"
                      // label="maximum conversion sent per month"
                      name="maxConversion"
                      variant="outlined"
                      fullWidth
                      value={maximumConversion}
                      onChange={(e) => setMaximumConversion(e.target.value)}
                      error={maximumConversionError}
                      helperText={maximumConversionHelperText}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end">
                  <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValue}
                      onChange={handleCheckboxChange}
                      color="primary"
                      className='flex align-item-end'
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 18, // Control the size of the Checkbox
                          color: '#646464', // Default color
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          color: 'rgb(150 98 255)', // Change color when checked
                        },
                      }}
                    />
                  }
                  label="Allow system to decide"
                />
              </Box>
            </form>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <Container maxWidth="md">
          <Box className="formBox">
            <form className="form">
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off for Major Holidays/Events </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="majorHoliday"
                    variant="outlined"
                    fullWidth
                    value={majorHolidays}
                    onChange={(e) => setMajorHolidays(e.target.value)}
                    error={majorHolidaysError}
                    helperText={majorHolidaysHelperText}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off for general promotions <br/>(anything not a major holiday or general event)</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="generalPromotion"
                    variant="outlined"
                    fullWidth
                    value={generalPromotion}
                    onChange={(e) => setGeneralPromotion(e.target.value)}
                    error={majorHolidaysError}
                    helperText={majorHolidaysHelperText}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off for General Events</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="generalEvents"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={generalEvents}
                    onChange={(e) => setGeneralEvents(e.target.value)}
                    error={generalEventsError}
                    helperText={generalEventsHelperText}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off for abandoned cart items</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="abandonedCartItem"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={abandonedCart}
                    onChange={(e) => setAbandonedCart(e.target.value)}
                    error={abandonedCartError}
                    helperText={abandonedCartHelperText}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off for first time buyer (for 2nd purchase)</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="firstTimeBuyer"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={firstTimeBuyer}
                    onChange={(e) => setFirstTimeBuyer(e.target.value)}
                    error={firstTimeBuyerError}
                    helperText={firstTimeBuyerHelperText}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off for Loyal Customers (shopped 5 or more times)</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="loyalCustomer"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={loyalCustomer}
                    onChange={(e) => setLoyalCustomer(e.target.value)}
                    error={loyalCustomerError}
                    helperText={loyalCustomerHelperText}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off for Birthdays</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="birthday"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={maxOffBirthday}
                    onChange={(e) => setMaxOffBirthday(e.target.value)}
                    error={maxOffBirthdayError}
                    helperText={maxOffBirthdayHelperText}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off for Follow Ups</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="followups"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={maxOffFollowups}
                    onChange={(e) => setMaxOffFollowups(e.target.value)}
                    error={maxOffBirthdayError}
                    helperText={maxOffBirthdayHelperText}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p variant="body1" className='promoContentText'>Provide the max % off if viewed a product at least 2 times</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="%"
                    name="viewedTwoTimes"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={viewedTwoTimes}
                    onChange={(e) => setViewedTwoTimes(e.target.value)}
                    error={maxOffBirthdayError}
                    helperText={maxOffBirthdayHelperText}
                  />
                </Grid>
                
              </Grid>
            </form>
          </Box>
        </Container>
        </CustomTabPanel>
    </div>
  );
}
 export default ContentPromoDetailsComponent
