import React from 'react'
import SubscriptionPlanComponent from '../../Components/SubscriptionPlanComponent/SubscriptionPlanComponent'

const SubscriptionPlanContainer = () => {
  return (
    <><SubscriptionPlanComponent/></>
  )
}

export default SubscriptionPlanContainer
