export const SUBSCRIPTIONS_ROUTES_URL = {
    GET_CURRENT_PLAN: 'api/plan/latest',
    UPGRADE_PLAN: 'api/upgrade-plan',
}

export const CREATEPROMOTION_ROUTES_URL = {
    CREATE_PROMOTION: 'api/create-promotion',
}

export const GET_LIST_SELECT_OPTIONS_ROUTES_URL = {
    GET_LIST_SELECT_OPTIONS: 'api/default-attribute',
    
}

export const GET_CATEGORY_LIST_ROUTES_URL = {
    GET_PROMOTION_CATEGORY_LIST: 'api/category',
}

export const GET_PROMOTION_LIST_ROUTES_URL = {
    GET_PROMOTION_LIST: 'api/user-promotion',
}

export const GET_PROMOTION_DETAILS_ROUTES_URL = {
    GET_PROMOTION_DETAILS: 'api/promotion',
}

export const EDIT_PROMOTION_ROUTES_URL = {
    EDIT_PROMOTION_DETAILS: 'api/edit-promotion',
}

export const GET_STORE_DETAILS_ROUTES_URL = {
    GET_STORE_DETAILS: 'api/store',
}