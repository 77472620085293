import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import './SidebarStyles.css'; // Import the CSS file
import config from '../../Config';
import { useGlobalContext } from '../../Context/GlobalContext';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setHaveRetailStoreLocation, setBothStoreType } = useGlobalContext();
  const navigate = useNavigate();
  const fullNameOfUser = localStorage.getItem('fullName');
  const companyNameOfUser = localStorage.getItem('companyName');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerItemClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const logOutFromSidebar = () => {
    setHaveRetailStoreLocation('');
    setBothStoreType('');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('ownerEmail');
    localStorage.removeItem('storeId');
    localStorage.removeItem('fsap');
    localStorage.removeItem('userId');
    localStorage.removeItem('storeExists');
    localStorage.removeItem('companyName');
    localStorage.removeItem('fullName');
    localStorage.removeItem('storeId');
    navigate('/login');
  }

  const drawerContent = (
    <div>
      <div className="toolbar">
        <img src={config.baseURL + "pushsend-logo.png"}
          alt='signup banner'
          className="w-190"
        />
      </div>
      {/* <Divider /> */}
      <div>
      <List>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <HomeIcon />
          </ListItemIcon>
          {/* <ListItemText primary="Dashboard" /> */}
          <Link to='/app/dashboard'><p className='text-[#2B3674] font-bold tracking-tight'>Dashboard</p></Link>
        </ListItem>
        <p className='text-[#2B3674] font-bold tracking-tight pl-10'>Promotions</p>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"Frame267.svg"}/>
          </ListItemIcon>
          <Link to='/app/create-promotion'><p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight" >Create Promotions</p></Link>
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"promotion 1.svg"}/>
          </ListItemIcon>
          <p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Suggested Promotions</p>
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"calendar 1.svg"}/>
          </ListItemIcon>
          <Link to='/app/promotion-list'><p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Marketing Calendar</p></Link>
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"settings 1.svg"}/>
          </ListItemIcon>
          <p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Promotional Settings</p>
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"Activity-Square.svg"}/>
          </ListItemIcon>
          <p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Analytics</p>
        </ListItem>
        <p className='text-[#2B3674] font-bold tracking-tight pl-10'>Customers</p>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"Tick-2.svg"}/>
          </ListItemIcon>
          <p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Customer Super Profile</p>
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"message 1.svg"}/>
          </ListItemIcon>
          <Link to='/app/inbox'><p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Inbox</p></Link>
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"conversation 1.svg"}/>
          </ListItemIcon>
          <p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Conversation Settings</p>
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"archive-down 1.svg"}/>
          </ListItemIcon>
          <p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Archive</p>
        </ListItem>
        <p className='text-[#2B3674] font-bold tracking-tight pl-10'>Store</p>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"user.svg"}/>
          </ListItemIcon>
          <p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Store Super Profile</p>
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: '20px' }}>
            <img src={process.env.REACT_APP_URL+"settings 1.svg"}/>
          </ListItemIcon>
          <Link to='/app/store-settings'><p className="text-[var(--Secondary-Grey-600,#A3AED0)] text-[14px] font-medium tracking-tight">Store Settings</p></Link>
        </ListItem>
      </List>
      </div>
    </div>
  );

  return (
    // <div>
    //   <IconButton
    //     color="inherit"
    //     aria-label="open drawer"
    //     edge="start"
    //     onClick={handleDrawerToggle}
    //     className="menuButton"
    //   >
    //     <MenuIcon />
    //   </IconButton>
    //   <Drawer
    //     variant="permanent"
    //     className="drawer"
    //     classes={{ paper: "drawerPaper" }}
    //     open
    //   >
    //     {drawerContent}
    //   </Drawer>
    //   <Drawer
    //     variant="temporary"
    //     open={mobileOpen}
    //     onClose={handleDrawerToggle}
    //     className="drawer"
    //     classes={{ paper: "drawerPaper" }}
    //     ModalProps={{
    //       keepMounted: true, // Better open performance on mobile.
    //     }}
    //   >
    //     {drawerContent}
    //   </Drawer>
    // </div>
    <div className="drawerContainer">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className="menuButton"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        className="drawer"
        classes={{ paper: "drawerPaper" }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div onClick={handleDrawerItemClick}>
          {drawerContent}
        </div>
        <div className='userLogoutsection'>
          <div className='sidebarUserDetails'>
            <div className='sidebarUserImageBackground'>
              <img src={process.env.REACT_APP_URL + 'user.svg'} className="sidebarUserImages" />
            </div>
            <div>
              <p className='text-[12px] text-[#333] font-medium'>{fullNameOfUser}</p>
              <p className='text-[12px] text-[#646464] font-medium'>{companyNameOfUser}</p>
            </div>
            <img src={process.env.REACT_APP_URL + 'logout 1.svg'} onClick={logOutFromSidebar} className='cursor-pointer' />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Sidebar;

