import React from 'react';
import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import config from '../../Config'
import  './LoginPageStyles.css';
import LoginContainer from '../../Containers/LoginContainer/LoginContainer'

function LoginPage() {
  return (
    <Grid container className="root">
      <Grid item xs={12} md={5} className='loginimgBackground'>
          <div className="flex items-center justify-center min-h-screen flex-col">
            <div className="w-358">
            <img src={config.baseURL+"login-banner.gif"}
              alt='loginlogo'
              className="w-200"
            />
            </div>
            <div className="mt-2.5 px-24">
              <p className="text-white text-center text-3xl font-medium leading-normal">The Customer Conversion <br></br>and Data Insights Platform</p>
            </div>
          </div>
          
      </Grid>
      <Grid item xs={12} md={7}>
        <div className="loginContainer">
          <div className='loginLogoDiv'> 
            <img src={config.baseURL+"pushsend-logo.png" } 
            className='loginLogo'
            alt='loginlogo'
            />
          </div>
          <CssBaseline />
          <div className="loginFormWrapper" elevation={3}>
              <p className="loginHeading" >
                Log in to your account
              </p>
              <p className="text-custom-gray text-14px leading-normal mt-3 ml-5">
              Welcome back! Please enter your details
              </p>
              <LoginContainer/>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default LoginPage;
