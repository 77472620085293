import axios from "axios";

const APIPAYMENT = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_API_URL,
});

APIPAYMENT.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expired or unauthorized, redirect to login
      localStorage.removeItem("token"); // Clear the token from localStorage
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

APIPAYMENT.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
  return req;
});
export default APIPAYMENT;
