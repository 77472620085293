import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../Context/GlobalContext';
import {postApi}from '../../apiClient';
import ScrollToTop from '../../Layout/ScrollToTop';

import  './OnboardingFinishStyles.css';

const OnboardingFinishComponent = ()=> {

  const { setLoading, showToast, setHaveRetailStoreLocation, setBothStoreType } = useGlobalContext();
  const navigate = useNavigate();

  const onboardingFinsh = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);
      let finishBoardingFormData = {
        storeId:localStorage.getItem("storeId"),
        userId:localStorage.getItem("userId"),
        isActive:true
      }
      let response = await postApi('api/activate-store',finishBoardingFormData)
      const { message} = response.data;
      if (response.status === 200 && message) {
        //showToast(message, 'success');
        setHaveRetailStoreLocation('')
        setBothStoreType([])
        setLoading(false);
        navigate('/app/dashboard');
      }
    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }

  }

  return (
    <>
      <ScrollToTop/>
      <div className='stepSevenWrapper'>
        <div className='containerSevenStep'>
          <img src={process.env.REACT_APP_URL+'Group 1171276084.png'} className='mt-8'/>
          <div className='completeSetupText'>
            <img src={process.env.REACT_APP_URL+'vector-tick.svg'}/>
            <p className="text-gray-800 text-[24px] not-italic font-bold leading-[30px] tracking-[-0.48px]"> Setup Completed</p>
          </div>
          <p className="text-gray-700 text-[14px] not-italic font-normal leading-normal"> Congratulations! your onboarding is completed</p>
        </div> 
          <div className='onboardFinishPageButton' onClick={onboardingFinsh}>
            <p className='onboardFinishButtonText'>Finish</p>
          </div>
      </div>
    </>
  )
}

 export default OnboardingFinishComponent
