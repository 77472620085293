import React, { useState, useRef, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { LoadScript, Autocomplete, GoogleMap, MarkerF } from '@react-google-maps/api';
import { useGlobalContext } from '../../Context/GlobalContext';
import Papa from 'papaparse';
import  './StoreLocationStyles.css';
import { loadGoogleMapsApi } from '../../loadGoogleMapsApi';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const containerStyle = {
  width: '50%',
  maxWidth: '160px',
  minWidth: '160px',
  height: '80px'
};

const StoreLocationComponent = ({storeLocation, setStoreLocation, storeLocationError, storeLocationHelperText, libraries})=> {

  const [autocomplete, setAutocomplete] = useState(null);
  const locationFieldRef = useRef(null);
  const { setLoading, showToast } = useGlobalContext();
  const mapRef = useRef(null);
  const fileInputRef = useRef(null);


  useEffect(() => {
    loadGoogleMapsApi(process.env.REACT_APP_GOOGLE_API_KEY, () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        const input = locationFieldRef.current;
        const autocompleteInstance = new window.google.maps.places.Autocomplete(input);
        autocompleteInstance.setFields(['formatted_address']);
        autocompleteInstance.addListener('place_changed', () => onPlaceChanged(autocompleteInstance));
        setAutocomplete(autocompleteInstance);
      } else {
        console.error('Google Maps API is not available.');
      }
    });
  }, []);

  const onPlaceChanged = useCallback((autocompleteInstance) => {
    setLoading(true);
    const place = autocompleteInstance.getPlace();
    if (!place || !place.formatted_address) {
      console.error('Place or formatted address is undefined.');
      setLoading(false);
      return;
    }
    const formattedAddress = place.formatted_address;
    geocodeAddress(formattedAddress)
      .then((coords) => {
        setStoreLocation((prevStoreLocation) => [
          ...prevStoreLocation,
          { coords, formattedAddress }
        ]);
      })
      .catch((error) => {
        console.error('Error fetching location:', error);
      })
      .finally(() => {
        setLoading(false);
        if (locationFieldRef.current) {
          locationFieldRef.current.value = '';
        }
      });
  }, []);

  const geocodeAddress = async (address) => {
    const geoResponse = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
    const geoData = await geoResponse.json();
    if (geoData.results.length > 0) {
      return geoData.results[0].geometry.location;
    } else {
      console.log('Something went wrong');
    }
  };

  const handleLocationDelete = (locationoDelete) => () => {
    setStoreLocation((currentLocations) => currentLocations.filter((loaction) => loaction !== locationoDelete));
  };

  const mapOptions = {
    fullscreenControl: true,
  };

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;

    // Apply custom styles to the fullscreen control
    const fullscreenControl = map.controls[window.google.maps.ControlPosition.RIGHT_TOP].getArray()[0];
    if (fullscreenControl) {
      fullscreenControl.style.width = '140px';
      fullscreenControl.style.height = '140px';

      const img = fullscreenControl.querySelector('img');
      if (img) {
        img.style.width = '100%';
        img.style.height = '100%';
      }
    }
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async (results) => {
          const parsedAddresses = results.data;
          const addressArray = [];
          const keyToCheck = 'Address';
          const someObjectsContainKey = parsedAddresses.some(obj => obj.hasOwnProperty(keyToCheck));
          if(someObjectsContainKey){
            setLoading(true);
            for (const resData of parsedAddresses) {
              if (resData.Address !== '') {
                try {
                  const coords = await geocodeAddress(resData.Address);
                  const formattedAddress = resData.Address;
                  addressArray.push({ coords, formattedAddress });
                  event.target.value = null;
                } catch (error) {
                  event.target.value = null;
                  console.error('Error fetching location:', error);
                }
              }
            }
            setStoreLocation([...storeLocation, ...addressArray])
            setLoading(false);
          }else{
            event.target.value = null;
            showToast('Please upload a right .csv file that you can download from Download Template', 'error');
          }
        },
        error: (error) => {
          console.error('Error parsing CSV file:', error);
        },
      });
    }
  }

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
      <div className='formContainerThirdStep'>
        <p className='headingFirstStep'>Add your retail stores</p>
        {/* <div className='downloadTitle'> */}
          <Grid container spacing={2} alignItems="center" padding='8px'>
           <Grid item xs={12} md={7} className='locationInfo'>Note: You can add multiple locations</Grid>
           <Grid item xs={12} md={5} className='downloadTemplateInfo'><Link className='downloadTemplate'><img src={process.env.REACT_APP_URL+'download-minimalistic.svg'} alt='download csv template' className='mr-1'/>Download CSV Template</Link></Grid> 
           </Grid>
        {/* </div> */}
        
        <form className='storeLocationForm' noValidate>
            <FormControl component="fieldset">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="shopLocation"
                label="Add location by store name or address"
                placeholder='Add location by store name or address'
                name="shopLocation"
                autoComplete="shopLocation"
                autoFocus
                inputRef={locationFieldRef}
                // value={locationData}
                // onChange={(e) => setStoreLocation(e.target.value)}
                error={storeLocationError}
                helperText={storeLocationHelperText}
                // sx={{ m: 1 }}
                // InputProps={{
                //   inputProps: {
                //     style: {
                //       textAlign: 'left',
                //       paddingLeft: 0,
                //       marginLeft: 0,
                //     },
                //   },
                //   startAdornment: (
                //     <InputAdornment position="start" >
                //       <IconButton>
                //           <img src={process.env.REACT_APP_URL+"location-plus.svg"} alt='info' className='locationIcon'/>
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
            </FormControl>
             
            <div className='LocationOr'>OR</div>
            <div className="file-upload" onClick={handleClick}>
              <input
                accept=".csv"
                className="file-input"
                id="file-upload"
                type="file"
                onChange={handleFileUpload}
                ref={fileInputRef}
              />
              <label className="file-upload-label">
              <img src={process.env.REACT_APP_URL+'upload-minimalistic.svg'} alt='upload loaction csv' className='mr-1'/>{"Upload CSV File"}
              </label>
            </div>
            <div className='locationListStyle'>
              { storeLocation?.map((sLocation, index) => (
                <div className='locationChip'>
                  <div className='mapAndLocation'>
                    {/* <img src={process.env.REACT_APP_URL+'Map-382.png'} alt='map' className='imgMap'/> */}
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={ sLocation.coords || { lat: 0, lng: 0 }} // Default center if location is not yet available
                      zoom={sLocation.coords ? 15 : 2} // Default zoom level
                      options={mapOptions}
                      onLoad={handleMapLoad}
                    >
                      <MarkerF position={sLocation.coords} />
                    </GoogleMap>
                    {sLocation.formattedAddress}
                  </div>
                  
                  <div className='crossOnLocation' onClick={handleLocationDelete(sLocation)}>
                    <img src={process.env.REACT_APP_URL+'Cross-1171276068.svg'} alt='cross'/>
                  </div>
                </div>
              ))}
            </div>
        </form>
      </div>
      
  )
}

 export default StoreLocationComponent
